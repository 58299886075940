import { useAppSelector, useAppDispatch } from '../app/hooks';

import useMapPlayerName from '../hooks/useMapPlayerName';

import { set_passErrState_state, set_傳失誤_state } from '../slices/baseballApp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const PassErrState = () => {

    const dispatch = useAppDispatch();
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    let { handleMapPlayerName } = useMapPlayerName();

    const handleChange = (event, newValue) => {
        dispatch(set_passErrState_state(newValue))
        let result = handleMapPlayerName(newValue)
        dispatch(set_傳失誤_state(result))
    }

    return (
        <ToggleButtonGroup color="primary" value={configs.passErrState} onChange={handleChange} size="small" exclusive>
            <ToggleButton value="1">1</ToggleButton>
            <ToggleButton value="2">2</ToggleButton>
            <ToggleButton value="3">3</ToggleButton>
            <ToggleButton value="4">4</ToggleButton>
            <ToggleButton value="5">5</ToggleButton>
            <ToggleButton value="6">6</ToggleButton>
            <ToggleButton value="7">7</ToggleButton>
            <ToggleButton value="8">8</ToggleButton>
            <ToggleButton value="9">9</ToggleButton>
        </ToggleButtonGroup>
    );
}

export default PassErrState;
