import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_事件_state } from '../slices/baseballApp';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

const PitcherState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const pitcherState = records.事件;

    const handleChange = (event, newValue) => {
        dispatch(set_事件_state(newValue))
    }

    return (
        <ToggleButtonGroup color="primary" value={pitcherState} onChange={handleChange} size="small" exclusive>
            <ToggleButton value="暴投">暴投</ToggleButton>
            <ToggleButton value="捕逸">捕逸</ToggleButton>
            <ToggleButton value="投手犯規">投手犯規</ToggleButton>
        </ToggleButtonGroup>
    );
}

export default PitcherState;
