import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_強中弱_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const PowerState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const powerState = records.強中弱;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={powerState} onClick={(event) => { dispatch(set_強中弱_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="強" control={<Radio size="small" color="primary" />} label="強" />
                <FormControlLabel style={style} value="中" control={<Radio size="small" color="primary" />} label="中" />
                <FormControlLabel style={style} value="弱" control={<Radio size="small" color="primary" />} label="弱" />
            </RadioGroup>
        </FormControl>
    );
}

export default PowerState;
