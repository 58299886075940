import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_fourScoreState_state, set_oneScoreState_state, set_threeScoreState_state, set_twoScoreState_state } from '../slices/baseballApp';

import Select from "react-select";
import Button from '@mui/material/Button';

function ScorerState() {
    const dispatch = useAppDispatch()
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (configs.attackTeamName === configs.guestTeamName) {
            const option = configs.guestRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)

            dispatch(set_oneScoreState_state(null))
            dispatch(set_twoScoreState_state(null))
            dispatch(set_threeScoreState_state(null))
            dispatch(set_fourScoreState_state(null))
        } else {
            const option = configs.homeRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)

            dispatch(set_oneScoreState_state(null))
            dispatch(set_twoScoreState_state(null))
            dispatch(set_threeScoreState_state(null))
            dispatch(set_fourScoreState_state(null))
        }
    }, [configs.attackTeamName])

    const handleClick = () => {
        dispatch(set_oneScoreState_state(null))
        dispatch(set_twoScoreState_state(null))
        dispatch(set_threeScoreState_state(null))
        dispatch(set_fourScoreState_state(null))
    }

    const handleOneChange = (event) => {
        dispatch(set_oneScoreState_state(event))
    }

    const handleTwoChange = (event) => {
        dispatch(set_twoScoreState_state(event))
    }

    const handleThreeChange = (event) => {
        dispatch(set_threeScoreState_state(event))
    }

    const handleFourChange = (event) => {
        dispatch(set_fourScoreState_state(event))
    }

    return (
        <>
            <Select value={configs.oneScoreState} options={options} onChange={handleOneChange} placeholder="得分者一" />
            <Select value={configs.twoScoreState} options={options} onChange={handleTwoChange} placeholder="得分者二" />
            <Select value={configs.threeScoreState} options={options} onChange={handleThreeChange} placeholder="得分者三" />
            <Select value={configs.fourScoreState} options={options} onChange={handleFourChange} placeholder="得分者四" />
            <Button onClick={handleClick} size="small" fullWidth variant="outlined" color="secondary">清空全得分者</Button>
        </>
    );
}

export default ScorerState;