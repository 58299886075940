import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_事件_state } from '../slices/baseballApp';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';

const MemoEvent = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let memoEvent = records.事件;

    let handleChange = (event, newValue) => {
        dispatch(set_事件_state(newValue))
    }

    return (
        <>
            <ToggleButtonGroup color="primary" value={memoEvent} onChange={handleChange} size="small" exclusive>
                <ToggleButton value="打帶跑">打帶跑</ToggleButton>
                <ToggleButton value="Pitch Out">Pitch Out</ToggleButton>
            </ToggleButtonGroup>
        </>
    );
}

export default MemoEvent;
