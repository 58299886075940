import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../app/hooks';

import Box from '@mui/material/Box';
import Select from "react-select";
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import {
    set_checkEarnedRunStateDialogOpen_state, set_firstEarnedRunToggleButtonValue_state,
    set_firstEarnedRun_state, set_fourthEarnedRunToggleButtonValue_state, set_fourthEarnedRun_state,
    set_secondEarnedRunToggleButtonValue_state, set_secondEarnedRun_state, set_thirdEarnedRunToggleButtonValue_state,
    set_thirdEarnedRun_state, set_失分_state, set_自責分_state
} from "../slices/baseballApp";

export default function useCheckEarnedRunStateDialog() {

    interface RosterOption {
        value: string
        label: string
    }

    const [options, setOptions] = useState<RosterOption[]>([]);

    const dispatch = useAppDispatch();
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    useEffect(() => {
        if (configs.attackTeamName === configs.guestTeamName) {
            const option = configs.homeRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option);
        } else {
            const option = configs.guestRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option);
        }
    }, [configs.attackTeamName])

    function handleCheckEarnedRunStateDialog() {

        const handleCheck = () => {
            handleCheckEarnedRunState()
            dispatch(set_checkEarnedRunStateDialogOpen_state(false))

            dispatch(set_firstEarnedRunToggleButtonValue_state(null));
            dispatch(set_secondEarnedRunToggleButtonValue_state(null));
            dispatch(set_thirdEarnedRunToggleButtonValue_state(null));
            dispatch(set_fourthEarnedRunToggleButtonValue_state(null));
            dispatch(set_firstEarnedRun_state(null));
            dispatch(set_secondEarnedRun_state(null));
            dispatch(set_thirdEarnedRun_state(null));
            dispatch(set_fourthEarnedRun_state(null));
        };

        const handleFirstToggleButtonChange = (event: any, newValue: any) => {
            dispatch(set_firstEarnedRunToggleButtonValue_state(newValue));
        };

        const handleSecondToggleButtonChange = (event: any, newValue: any) => {
            dispatch(set_secondEarnedRunToggleButtonValue_state(newValue));
        };

        const handleThirdToggleButtonChange = (event: any, newValue: any) => {
            dispatch(set_thirdEarnedRunToggleButtonValue_state(newValue));
        };

        const handleFourthToggleButtonChange = (event: any, newValue: any) => {
            dispatch(set_fourthEarnedRunToggleButtonValue_state(newValue));
        };

        const handleCheckEarnedRunState = () => {
            // 失分、自責分邏輯對照VBA邏輯移植
            if (configs.firstEarnedRun !== null) {
                dispatch(set_失分_state(String(configs.firstEarnedRun.value)))
                if (configs.firstEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value)))
                };
            };
            if (configs.firstEarnedRun !== null && configs.secondEarnedRun !== null) {
                dispatch(set_失分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value)))
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value)))
                };
            };
            if (configs.firstEarnedRun !== null && configs.secondEarnedRun !== null && configs.thirdEarnedRun !== null) {
                dispatch(set_失分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value)))
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + '.' + String(configs.thirdEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + '.' + String(configs.thirdEarnedRun.value)))
                };
            };
            if (configs.firstEarnedRun !== null && configs.secondEarnedRun !== null && configs.thirdEarnedRun !== null && configs.fourthEarnedRun !== null) {
                dispatch(set_失分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value) + '.' + String(configs.fourthEarnedRun.value)))
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value) + '.' + String(configs.fourthEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + '.' + String(configs.fourthEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + '.' + String(configs.thirdEarnedRun.value) + '.' + String(configs.fourthEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value) + '.' + String(configs.fourthEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + String(configs.secondEarnedRun.value) + '.' + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + '.' + String(configs.thirdEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + '.' + '.' + String(configs.fourthEarnedRun.value)))
                };;
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + '.' + String(configs.thirdEarnedRun.value) + '.' + String(configs.fourthEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value) + '.' + '.' + String(configs.fourthEarnedRun.value)))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value) + '.' + String(configs.thirdEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue !== null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state(String(configs.firstEarnedRun.value) + '.' + '.' + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue !== null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state('.' + String(configs.secondEarnedRun.value) + '.' + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue !== null && configs.fourthEarnedRunToggleButtonValue === null) {
                    dispatch(set_自責分_state('.' + '.' + String(configs.thirdEarnedRun.value) + '.'))
                };
                if (configs.firstEarnedRunToggleButtonValue === null && configs.secondEarnedRunToggleButtonValue === null && configs.thirdEarnedRunToggleButtonValue === null && configs.fourthEarnedRunToggleButtonValue !== null) {
                    dispatch(set_自責分_state('.' + '.' + '.' + String(configs.fourthEarnedRun.value)))
                };
            };
        };

        return (
            <>
                <Dialog open={configs.checkEarnedRunStateDialogOpen} onClose={handleCheck} fullWidth={true}>
                    <DialogTitle>確認失分責任</DialogTitle>
                    <DialogContent dividers={true} >

                        <Box sx={{ m: 1 }}>
                            <Select value={configs.firstEarnedRun} options={options} onChange={(event) => { dispatch(set_firstEarnedRun_state(event)) }} placeholder="失第一分" />
                            <ToggleButtonGroup color="primary" value={configs.firstEarnedRunToggleButtonValue} onChange={handleFirstToggleButtonChange} size="small" exclusive>
                                <ToggleButton value="1">自責分</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{ m: 1 }}>
                            <Select value={configs.secondEarnedRun} options={options} onChange={(event) => { dispatch(set_secondEarnedRun_state(event)) }} placeholder="失第二分" />
                            <ToggleButtonGroup color="primary" value={configs.secondEarnedRunToggleButtonValue} onChange={handleSecondToggleButtonChange} size="small" exclusive>
                                <ToggleButton value="2">自責分</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{ m: 1 }}>
                            <Select value={configs.thirdEarnedRun} options={options} onChange={(event) => { dispatch(set_thirdEarnedRun_state(event)) }} placeholder="失第三分" />
                            <ToggleButtonGroup color="primary" value={configs.thirdEarnedRunToggleButtonValue} onChange={handleThirdToggleButtonChange} size="small" exclusive>
                                <ToggleButton value="3">自責分</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box sx={{ m: 1, mb: 5 }}>
                            <Select value={configs.fourthEarnedRun} options={options} onChange={(event) => { dispatch(set_fourthEarnedRun_state(event)) }} placeholder="失第四分" />
                            <ToggleButtonGroup color="primary" value={configs.fourthEarnedRunToggleButtonValue} onChange={handleFourthToggleButtonChange} size="small" exclusive>
                                <ToggleButton value="4">自責分</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" fullWidth onClick={handleCheck}>確認</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    };

    return handleCheckEarnedRunStateDialog;

};
