import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../app/hooks';
import {
    set_guestBallPitchCount_state, set_guestInPlayPitchCount_state, set_guestInPlayPitcherName_state, set_guestStrikePitchCount_state,
    set_homeBallPitchCount_state, set_homeInPlayPitchCount_state, set_homeInPlayPitcherName_state, set_homeStrikePitchCount_state
} from "../slices/baseballApp";

import Box from '@mui/material/Box';
import Select from "react-select";

function ChangePitcher() {

    const [options, setOptions] = useState([]);
    const [selectValue, setSelectValue] = useState([]);

    const dispatch = useAppDispatch()
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    useEffect(() => {
        if (configs.attackTeamName === configs.guestTeamName) {
            const option = configs.homeRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)
            setSelectValue(null)
        } else {
            const option = configs.guestRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)
            setSelectValue(null)
        }
    }, [configs.attackTeamName])

    const handleChangePitcher = (e) => {
        if (configs.attackTeamName === configs.guestTeamName) {
            setSelectValue(e)
            dispatch(set_homeInPlayPitchCount_state(0))
            dispatch(set_homeStrikePitchCount_state(0))
            dispatch(set_homeBallPitchCount_state(0))
            dispatch(set_homeInPlayPitcherName_state(String(e.value)))
        } else {
            setSelectValue(e)
            dispatch(set_guestInPlayPitchCount_state(0))
            dispatch(set_guestStrikePitchCount_state(0))
            dispatch(set_guestBallPitchCount_state(0))
            dispatch(set_guestInPlayPitcherName_state(String(e.value)))
        }
    }

    return (
        <>
            <Box>
                <Select value={selectValue} onChange={handleChangePitcher} options={options} placeholder="更換當前投手" />
            </Box>
        </>
    );
}

export default ChangePitcher;
