import { useState } from "react";
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
    set_guestLineup_state, set_guestTeamName_state,
    set_homeTeamName_state, set_homeLineup_state, set_attackTeamName_state,
    set_defenseTeamName_state, set_guestInPlayPitcherName_state, set_homeInPlayPitcherName_state,
    set_homeInPlayBatterName_state, set_homeInPlayCatcherName_state,
    set_guestInPlayCatcherName_state, set_guestInPlayBatterName_state,
    set_homeRoster_state, set_guestRoster_state,
    clean_guestLineup_state, clean_homeLineup_state, clean_guestRoster_state,
    clean_homeRoster_state
} from '../slices/baseballApp';
import Airtable from 'airtable';

export default function useSetLineUp() {

    interface LineUp {
        隊伍: string
        姓名: string
        背號: number
        打序: number | any
        守位: number | any
        慣用手: string
    }

    const dispatch = useAppDispatch();
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    const [guestFinalLineUp, setGuestFinalLineUp] = useState<LineUp[]>([]);
    const [homeFinalLineUp, setHomeFinalLineUp] = useState<LineUp[]>([]);
    const [loadingState, setLoadingState] = useState(false);

    function handleSetLineup(recordApiKey: string) {

        setGuestFinalLineUp([]);
        setHomeFinalLineUp([]);
        dispatch(clean_guestLineup_state());
        dispatch(clean_homeLineup_state());
        dispatch(clean_guestRoster_state());
        dispatch(clean_homeRoster_state());

        const recordBase = new Airtable({ apiKey: 'keya2Fq5zTPhOH0Ld' }).base(recordApiKey);

        recordBase('raw_guest_lineup')
            .select({ view: 'Grid view' })
            .eachPage((records: any, fetchNextPage) => {
                for (let i = 0; i < records.length; i++) {
                    dispatch(set_guestRoster_state({ 隊伍: records[i].fields.隊伍, 姓名: records[i].fields.姓名, 背號: records[i].fields.背號, 打序: records[i].fields.打序, 守位: records[i].fields.守位, 慣用手: records[i].fields.慣用手 }))
                    for (let order = 1; order < 11; order++) {
                        if (records[i].fields.守位 === String(order)) {
                            setGuestFinalLineUp(lastLineUp => [...lastLineUp, { 隊伍: records[i].fields.隊伍, 姓名: records[i].fields.姓名, 背號: records[i].fields.背號, 打序: records[i].fields.打序, 守位: records[i].fields.守位, 慣用手: records[i].fields.慣用手 }]);
                            dispatch(set_guestLineup_state({ 隊伍: records[i].fields.隊伍, 姓名: records[i].fields.姓名, 背號: records[i].fields.背號, 打序: records[i].fields.打序, 守位: records[i].fields.守位, 慣用手: records[i].fields.慣用手 }))
                        }
                        if (records[i].fields.守位 === '1') {
                            dispatch(set_guestInPlayPitcherName_state(records[i].fields.姓名))
                        }
                        if (records[i].fields.守位 === '2') {
                            dispatch(set_guestInPlayCatcherName_state(records[i].fields.姓名))
                        }
                        if (records[i].fields.打序 === '1') {
                            dispatch(set_guestInPlayBatterName_state(records[i].fields.姓名))
                        }
                    }
                }
                // fetchNextPage(); // 如資料超過100筆需切割請求
                if (configs.guestTeamName === "" && configs.attackTeamName === "") {
                    dispatch(set_guestTeamName_state(records[0].fields.隊伍))
                    dispatch(set_attackTeamName_state(records[0].fields.隊伍))
                }
            });


        recordBase('raw_home_lineup')
            .select({ view: 'Grid view' })
            .eachPage((records: any, fetchNextPage) => {
                for (let i = 0; i < records.length; i++) {
                    dispatch(set_homeRoster_state({ 隊伍: records[i].fields.隊伍, 姓名: records[i].fields.姓名, 背號: records[i].fields.背號, 打序: records[i].fields.打序, 守位: records[i].fields.守位, 慣用手: records[i].fields.慣用手 }))
                    for (let order = 1; order < 11; order++) {
                        if (records[i].fields.守位 === String(order)) {
                            setHomeFinalLineUp(lastLineUp => [...lastLineUp, { 隊伍: records[i].fields.隊伍, 姓名: records[i].fields.姓名, 背號: records[i].fields.背號, 打序: records[i].fields.打序, 守位: records[i].fields.守位, 慣用手: records[i].fields.慣用手 }]);
                            dispatch(set_homeLineup_state({ 隊伍: records[i].fields.隊伍, 姓名: records[i].fields.姓名, 背號: records[i].fields.背號, 打序: records[i].fields.打序, 守位: records[i].fields.守位, 慣用手: records[i].fields.慣用手 }))
                        }
                        if (records[i].fields.守位 === '1') {
                            dispatch(set_homeInPlayPitcherName_state(records[i].fields.姓名))
                        }
                        if (records[i].fields.守位 === '2') {
                            dispatch(set_homeInPlayCatcherName_state(records[i].fields.姓名))
                        }
                        if (records[i].fields.打序 === '1') {
                            dispatch(set_homeInPlayBatterName_state(records[i].fields.姓名))
                        }
                    }
                }
                // fetchNextPage(); // 如資料超過100筆需切割請求
                if (configs.homeTeamName === "" && configs.defenseTeamName === "") {
                    dispatch(set_homeTeamName_state(records[0].fields.隊伍))
                    dispatch(set_defenseTeamName_state(records[0].fields.隊伍))
                }
                setLoadingState(false) // 釋放讀取狀態
            });


    }

    return {
        guestFinalLineUp, homeFinalLineUp, loadingState,
        setGuestFinalLineUp, setHomeFinalLineUp, setLoadingState,
        handleSetLineup
    };

}