import { useState, useEffect } from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import {
    set_firstBaseState_state, set_secondBaseState_state, set_thirdBaseState_state, set_homePlateState_state,
    set_一壘跑者_state, set_二壘跑者_state, set_三壘跑者_state
} from "../slices/baseballApp";

import Select from "react-select";
import Button from '@mui/material/Button';

function BaseState() {
    const dispatch = useAppDispatch()
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    const [options, setOptions] = useState([]);

    useEffect(() => {
        if (configs.attackTeamName === configs.guestTeamName) {
            const option = configs.guestRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)
        } else {
            const option = configs.homeRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)
        }
    }, [configs.attackTeamName])

    const handleClick = () => {
        dispatch(set_一壘跑者_state(""))
        dispatch(set_二壘跑者_state(""))
        dispatch(set_三壘跑者_state(""))

        dispatch(set_firstBaseState_state(null))
        dispatch(set_secondBaseState_state(null))
        dispatch(set_thirdBaseState_state(null))
        dispatch(set_homePlateState_state(null))
    }

    return (
        <>
            <Select value={configs.firstBaseState} options={options} onChange={(event) => { dispatch(set_一壘跑者_state(event.value)); dispatch(set_firstBaseState_state(event)) }} placeholder="一壘跑者" />
            <Select value={configs.secondBaseState} options={options} onChange={(event) => { dispatch(set_二壘跑者_state(event.value)); dispatch(set_secondBaseState_state(event)) }} placeholder="二壘跑者" />
            <Select value={configs.thirdBaseState} options={options} onChange={(event) => { dispatch(set_三壘跑者_state(event.value)); dispatch(set_thirdBaseState_state(event)) }} placeholder="三壘跑者" />
            <Button onClick={handleClick} size="small" fullWidth variant="outlined" color="secondary">清空全跑者</Button>
        </>
    );
}

export default BaseState;
