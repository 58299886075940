import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_事件_state, set_結果_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const OutHavePointState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const outHavePointState = records.結果;
    const eventState = records.事件;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={outHavePointState} onClick={(event) => { dispatch(set_結果_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="Bunt" control={<Radio size="small" color="primary" />} label="Bunt" />
                <FormControlLabel style={style} value="飛犧" control={<Radio size="small" color="primary" />} label="飛犧" />
            </RadioGroup>
            <RadioGroup value={eventState} onClick={(event) => { dispatch(set_事件_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="推進" control={<Radio size="small" color="primary" />} label="推進" />
            </RadioGroup>
        </FormControl>
    );
}

export default OutHavePointState;
