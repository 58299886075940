import React from 'react';
import { useState, useEffect } from 'react';

import ReactImageDot from './components/ReactImageDot';
// import DotsInfo from './components/DotsInfo';
import styles from './css/styles.css';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { set_進壘點_x_state, set_進壘點_y_state, set_kZoneBox_state } from '../../slices/baseballApp';

const KzoneImageDot = () => {

  const configs = useAppSelector(state => state.baseballAppReducer.config);

  const dispatch = useAppDispatch();
  const addDot = (dot) => {
    dispatch(set_kZoneBox_state(dot));
    dispatch(set_進壘點_x_state(dot.x));
    dispatch(set_進壘點_y_state(dot.y));
    // this.setState({
    //   dots: [
    //     // ...this.state.dots,
    //     dot,
    //   ],
    // });
  }

  // const deleteDot = (index) => {
  //   this.setState({
  //     dots: this.state.dots.filter((e, i) => {
  //       return i !== index;
  //     }),
  //   });
  // }

  // const resetDots = () => {
  //   this.setState({
  //     dots: this.initialDots,
  //   });
  // }

  return (
    <div>
      <ReactImageDot
        backgroundImageUrl='https://upload.cc/i1/2022/03/16/2dYzC6.png'
        width={230}
        height={285}
        dots={configs.kZoneBox}
        // deleteDot={deleteDot}
        addDot={addDot}
        dotRadius={6}
        dotStyles={{
          backgroundColor: 'red',
          boxShadow: '0 2px 4px gray',
        }}
        backgroundSize={'cover'}
      />
      {/* <button onClick={this.resetDots}>Reset</button> */}
      {/* <DotsInfo
        height={230}
        width={285}
        dots={configs.kZoneBox}
        deleteDot={deleteDot}
      /> */}
    </div>
  );

}

export default KzoneImageDot;