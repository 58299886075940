import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_球速_state } from '../slices/baseballApp';

import TextField from '@mui/material/TextField';

const BallVelocity = () => {

    let dispatch = useAppDispatch();
    let records = useAppSelector(state => state.baseballAppReducer.records);

    let ballVelocity = records.球速;

    return (
        <>
            <TextField value={ballVelocity} onChange={(event) => { dispatch(set_球速_state(event.target.value)) }} fullWidth size="small" margin="none" label="球速 km/h" variant="outlined"/>
        </>
    );
}

export default BallVelocity;
