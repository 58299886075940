import { useAppSelector } from '../app/hooks';

import useCheckBaseStateDialog from '../hooks/useCheckBaseStateDialog';
import useCheckChangeInningDialog from '../hooks/useCheckChangeInningDialog';
import useSubInningDialog from '../hooks/useSubInningDialog';
import useCheckEarnedRunStateDialog from '../hooks/useCheckEarnedRunStateDialog';

import BallType from '../components/ballType';
import BallVelocity from '../components/ballVelocity';
import KzoneImageDot from '../components/kZoneBox/KzoneImageDot';
import PlayPass from '../components/playPass';
import StoreBtn from '../components/storeBtn';
import MarkIn from '../components/markIn';
import MarkOut from '../components/markOut';
import SwingState from '../components/swingState';
import BattingState from '../components/battingState';
import HaveSwingState from '../components/haveSwingState';
import GroundState from '../components/groundState';
import PowerState from '../components/powerState';
import OutState from '../components/outState';
import OutHavePointState from '../components/outHavePointState';
import HitState from '../components/hitState';
import EarnedRunState from '../components/earnedRunState';
import OnceCatchState from '../components/onceCatchState';
import TwiceCatchState from '../components/twiceCatchState';
import ThriceCatchState from '../components/thriceCatchState';
import FourCatchState from '../components/fourCatchState';
import PassErrState from '../components/passErrState';
import CatchErrState from '../components/catchErrState';
import AllBasesAddBtn from '../components/allBasesAddBtn';
// import ResetRoster from '../components/resetRoster';
import LuckyState from '../components/luckyState';
import BaseEvent from '../components/baseEvent';
import BaseState from '../components/baseState';
import ScorerState from '../components/scorerState';
import OutBaseEvent from '../components/outBaseEvent';
import RbiState from '../components/rbiState';
import PitcherState from '../components/pitcherState';
import MemoEvent from '../components/memoEvent';
import FieldZoneImageDot from '../components/fieldZoneBox/fieldZoneImageDot';
import RecordEventBtn from '../components/recordEventBtn';
import ChangePitcher from '../components/changePitcher';
import ChangeBatter from '../components/changeBatter';
import SyncKarmaZoneDataBtn from '../components/syncKarmaZoneDataBtn';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import CircleIcon from '@mui/icons-material/Circle';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

function RecordPage() {

    const records = useAppSelector(state => state.baseballAppReducer.records);
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    const handleCheckBaseStateDialog = useCheckBaseStateDialog();
    const handleCheckChangeInningDialog = useCheckChangeInningDialog();
    const handleSubInningDialog = useSubInningDialog();
    const handleCheckEarnedRunStateDialog = useCheckEarnedRunStateDialog();

    return (
        <Box
            sx={{
                mx: 'auto',
                width: 'auto',
                p: 1,
                m: 1,
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                textAlign: 'center',
                fontSize: '0.875rem',
                fontWeight: '700',
            }}
        >
            <Grid spacing={1} container>

                {handleCheckBaseStateDialog()}
                {handleCheckChangeInningDialog()}
                {handleSubInningDialog()}
                {handleCheckEarnedRunStateDialog()}

                <Grid item xs={12} sm={12} md={4}>
                    <Paper variant="outlined" >
                        <TableContainer>
                            <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">場上投手</TableCell>
                                        <TableCell align="center">場上打者</TableCell>
                                        <TableCell align="center">場上捕手</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {configs.attackTeamName === configs.guestTeamName ? (
                                            <>
                                                <TableCell align="center"><b>{configs.homeInPlayPitcherNumber}&nbsp;{configs.homeInPlayPitcherName}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestInPlayBatterNumber}&nbsp;{configs.guestInPlayBatterName}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeInPlayCatcherNumber}&nbsp;{configs.homeInPlayCatcherName}</b></TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell align="center"><b>{configs.guestInPlayPitcherNumber}&nbsp;{configs.guestInPlayPitcherName}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeInPlayBatterNumber}&nbsp;{configs.homeInPlayBatterName}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestInPlayCatcherNumber}&nbsp;{configs.guestInPlayCatcherName}</b></TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Paper variant="outlined" >
                        <TableContainer>
                            <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">打序</TableCell>
                                        <TableCell align="center">好球</TableCell>
                                        <TableCell align="center">壞球</TableCell>
                                        <TableCell align="center">出局</TableCell>
                                        <TableCell align="center">比分</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {configs.attackTeamName === configs.guestTeamName ? (
                                            <>
                                                <TableCell align="center"><b>{configs.guestBattingOrder}</b></TableCell>
                                                {configs.strikeState === 0 && <TableCell align="center"><b>{configs.strikeState}</b></TableCell>}
                                                {configs.strikeState === 1 && <TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="warning" /></TableCell>}
                                                {configs.strikeState === 2 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="warning" /><CircleIcon sx={{ fontSize: 10 }} color="warning" /></TableCell></>}
                                                {configs.strikeState === 3 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="warning" /><CircleIcon sx={{ fontSize: 10 }} color="warning" /><CircleIcon sx={{ fontSize: 10 }} color="warning" /></TableCell></>}

                                                {configs.ballState === 0 && <TableCell align="center"><b>{configs.ballState}</b></TableCell>}
                                                {configs.ballState === 1 && <TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell>}
                                                {configs.ballState === 2 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell></>}
                                                {configs.ballState === 3 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell></>}
                                                {configs.ballState === 4 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell></>}

                                                {configs.outState === 0 && <TableCell align="center"><b>{configs.outState}</b></TableCell>}
                                                {configs.outState === 1 && <TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="error" /></TableCell>}
                                                {configs.outState === 2 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="error" /><CircleIcon sx={{ fontSize: 10 }} color="error" /></TableCell></>}
                                                {configs.outState === 3 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="error" /><CircleIcon sx={{ fontSize: 10 }} color="error" /><CircleIcon sx={{ fontSize: 10 }} color="error" /></TableCell></>}
                                                <TableCell align="center"><b>{records.客隊得分}:{records.主隊得分}</b></TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell align="center"><b>{configs.homeBattingOrder}</b></TableCell>
                                                {configs.strikeState === 0 && <TableCell align="center"><b>{configs.strikeState}</b></TableCell>}
                                                {configs.strikeState === 1 && <TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="warning" /></TableCell>}
                                                {configs.strikeState === 2 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="warning" /><CircleIcon sx={{ fontSize: 10 }} color="warning" /></TableCell></>}
                                                {configs.strikeState === 3 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="warning" /><CircleIcon sx={{ fontSize: 10 }} color="warning" /><CircleIcon sx={{ fontSize: 10 }} color="warning" /></TableCell></>}

                                                {configs.ballState === 0 && <TableCell align="center"><b>{configs.ballState}</b></TableCell>}
                                                {configs.ballState === 1 && <TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell>}
                                                {configs.ballState === 2 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell></>}
                                                {configs.ballState === 3 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell></>}
                                                {configs.ballState === 4 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /><CircleIcon sx={{ fontSize: 10 }} color="success" /></TableCell></>}

                                                {configs.outState === 0 && <TableCell align="center"><b>{configs.outState}</b></TableCell>}
                                                {configs.outState === 1 && <TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="error" /></TableCell>}
                                                {configs.outState === 2 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="error" /><CircleIcon sx={{ fontSize: 10 }} color="error" /></TableCell></>}
                                                {configs.outState === 3 && <><TableCell align="center"><CircleIcon sx={{ fontSize: 10 }} color="error" /><CircleIcon sx={{ fontSize: 10 }} color="error" /><CircleIcon sx={{ fontSize: 10 }} color="error" /></TableCell></>}
                                                <TableCell align="center"><b>{records.客隊得分}:{records.主隊得分}</b></TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid >
                <Grid item xs={12} sm={12} md={4}>
                    <Paper variant="outlined" >
                        <TableContainer>
                            <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center">進攻隊伍</TableCell>
                                        <TableCell align="center">總用球數</TableCell>
                                        <TableCell align="center">投手球數</TableCell>
                                        <TableCell align="center">局</TableCell>
                                        <TableCell align="center">R</TableCell>
                                        <TableCell align="center">H</TableCell>
                                        <TableCell align="center">E</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        {configs.attackTeamName === configs.guestTeamName ? (
                                            <>
                                                <TableCell align="center"><b>{configs.attackTeamName}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeTotalPitchCount}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeInPlayPitchCount}</b></TableCell>
                                                <TableCell align="center"><b>{configs.inningType}&nbsp;{configs.inning}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestR}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestH}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestE}</b></TableCell>
                                            </>
                                        ) : (
                                            <>
                                                <TableCell align="center"><b>{configs.attackTeamName}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestTotalPitchCount}</b></TableCell>
                                                <TableCell align="center"><b>{configs.guestInPlayPitchCount}</b></TableCell>
                                                <TableCell align="center"><b>{configs.inningType}&nbsp;{configs.inning}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeR}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeH}</b></TableCell>
                                                <TableCell align="center"><b>{configs.homeE}</b></TableCell>
                                            </>
                                        )}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            p: 1,
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <KzoneImageDot />
                    </Box>
                    {configs.karmaZoneHost !== "" && (
                        <Box
                            my={0.5}
                            sx={{
                                bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                                color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                                border: '1px solid',
                                borderColor: (theme) =>
                                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                                p: 1,
                                borderRadius: 2,
                                fontSize: '0.875rem',
                                fontWeight: '700',
                            }}
                        >
                            <SyncKarmaZoneDataBtn />
                        </Box>
                    )}
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            p: 1,
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <BallVelocity />
                    </Box>
                    <Box
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <BallType />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            p: 1,
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <PlayPass />
                    </Box>
                    <Box my={1.5}>
                        <StoreBtn />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <Box>
                        <MarkIn />
                    </Box>
                    <Box my={0.5}>
                        <MarkOut />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <SwingState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <BattingState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <HaveSwingState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <GroundState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <PowerState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <OutState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <OutHavePointState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <HitState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        一接&nbsp;<OnceCatchState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        二接&nbsp;<TwiceCatchState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        三接&nbsp;<ThriceCatchState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        四接&nbsp;<FourCatchState />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <Box>
                        <StoreBtn />
                    </Box>
                    <Box my={0.5}>
                        <AllBasesAddBtn />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <LuckyState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <BaseEvent />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <OutBaseEvent />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        傳失&nbsp;<PassErrState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        接失&nbsp;<CatchErrState />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                    <Box
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <ChangePitcher />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <ChangeBatter />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <EarnedRunState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <RbiState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <BaseState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <ScorerState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <PitcherState />
                    </Box>
                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <MemoEvent />
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                    <Box
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <FieldZoneImageDot />
                    </Box>

                    {configs.karmaZoneHost !== "" && (
                        <Paper variant="outlined" >
                            <TableContainer>
                                <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">時間</TableCell>
                                            <TableCell align="center">編號</TableCell>
                                            <TableCell align="center">球速</TableCell>
                                            {configs.karmaZoneHost === "Tianmu" && <TableCell align="center">轉速</TableCell>}
                                            <TableCell align="center">初速</TableCell>
                                            <TableCell align="center">仰角</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {configs.allKarmaZoneData !== "" ? (
                                                <>
                                                    <TableCell align="center"><b>{configs.allKarmaZoneData[configs.karmaZoneHost]['Time']}</b></TableCell>
                                                    <TableCell align="center"><b>{configs.allKarmaZoneData[configs.karmaZoneHost]['Clip_Id']}</b></TableCell>
                                                    <TableCell align="center"><b>{configs.allKarmaZoneData[configs.karmaZoneHost]['Velo-rel']}</b></TableCell>
                                                    {configs.karmaZoneHost === "Tianmu" && (<TableCell align="center"><b>{configs.allKarmaZoneData['Tianmu']['Clip_Id'] === configs.allKarmaZoneData['Tianmu 4Spin']['Clip_Id'] ? (configs.allKarmaZoneData['Tianmu 4Spin']['Spin_Rate']) : ("null")}</b></TableCell>)}
                                                    <TableCell align="center"><b>{configs.allKarmaZoneData[configs.karmaZoneHost]['E_Velo']}</b></TableCell>
                                                    <TableCell align="center"><b>{configs.allKarmaZoneData[configs.karmaZoneHost]['E_Angle']}</b></TableCell>
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                    {configs.karmaZoneHost === "Tianmu" && (<TableCell align="center"><b>null</b></TableCell>)}
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}

                    {configs.karmaZoneHost === "Tianmu" && (
                        <Paper variant="outlined" >
                            <TableContainer>
                                <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                    <TableHead>
                                        <TableRow>
                                            {configs.karmaZoneHost === "Tianmu" && <TableCell align="center">Axis</TableCell>}
                                            {configs.karmaZoneHost === "Tianmu" && <TableCell align="center">H-Move</TableCell>}
                                            {configs.karmaZoneHost === "Tianmu" && <TableCell align="center">V-Move</TableCell>}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {configs.allKarmaZoneData !== "" ? (
                                                <>
                                                    {configs.karmaZoneHost === "Tianmu" && (<TableCell align="center"><b>{configs.allKarmaZoneData['Tianmu']['Clip_Id'] === configs.allKarmaZoneData['Tianmu 4Spin']['Clip_Id'] ? (configs.allKarmaZoneData['Tianmu 4Spin']['Axis1']) : ("null")}</b></TableCell>)}
                                                    {configs.karmaZoneHost === "Tianmu" && (<TableCell align="center"><b>{configs.allKarmaZoneData['Tianmu']['Clip_Id'] === configs.allKarmaZoneData['Tianmu 4HorizontalMovement']['Clip_Id'] ? (configs.allKarmaZoneData['Tianmu 4HorizontalMovement']['Movement']) : ("null")}</b></TableCell>)}
                                                    {configs.karmaZoneHost === "Tianmu" && (<TableCell align="center"><b>{configs.allKarmaZoneData['Tianmu']['Clip_Id'] === configs.allKarmaZoneData['Tianmu 3VerticalMovement']['Clip_Id'] ? (configs.allKarmaZoneData['Tianmu 3VerticalMovement']['Movement']) : ("null")}</b></TableCell>)}
                                                </>
                                            ) : (
                                                <>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                    <TableCell align="center"><b>null</b></TableCell>
                                                </>
                                            )}
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )}

                    <Box
                        my={0.5}
                        sx={{
                            bgcolor: (theme) => (theme.palette.mode === 'dark' ? '#101010' : '#fff'),
                            color: (theme) => (theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800'),
                            border: '1px solid',
                            borderColor: (theme) =>
                                theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                            borderRadius: 2,
                            fontSize: '0.875rem',
                            fontWeight: '700',
                        }}
                    >
                        <RecordEventBtn />
                    </Box>
                </Grid>

            </Grid >
        </Box >
    );
}

export default RecordPage;
