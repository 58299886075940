import { useAppDispatch, useAppSelector } from '../app/hooks';
import { set_mark_in_state } from '../slices/baseballApp';

import Button from '@mui/material/Button';

const MarkIn = () => {

    const records = useAppSelector(state => state.baseballAppReducer.records);
    let dispatch = useAppDispatch()

    let markInHandleClick = () => {
        const current = new Date();
        const time = `${current.getTime()}`;
        dispatch(set_mark_in_state(time))
    }

    return (
        <>
            {records.mark_in === "" ? (
                <Button onClick={markInHandleClick} size="large" fullWidth variant="outlined" color="info">mark in</Button>
            ) : (
                <Button onClick={markInHandleClick} size="large" fullWidth variant="contained" color="info">mark in</Button>
            )}
        </>
    );
}

export default MarkIn;
