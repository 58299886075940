import { useState, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import { set_syncKarmaZoneData_state } from '../slices/baseballApp';

import useFetchKarmaZoneData from '../hooks/useFetchKarmaZoneData';
import Button from '@mui/material/Button';

function SyncKarmaZoneDataBtn() {

    const dispatch = useAppDispatch();
    const handleFetchKarmaZoneData = useFetchKarmaZoneData();

    const configs = useAppSelector((state) => state.baseballAppReducer.config);
    const [timerState, setTimerState] = useState();

    const fetchKarmaZoneData = () => {
        if (configs.karmaZoneHost !== "") {
            const interval = setInterval(() => {
                handleFetchKarmaZoneData();
            }, 2000);
            setTimerState(interval);
        }
    }

    const handleClick = () => {
        switch (configs.syncKarmaZoneData) {
            case true:
                clearInterval(timerState)
                dispatch(set_syncKarmaZoneData_state(false))
                break;
            case false:
                fetchKarmaZoneData()
                dispatch(set_syncKarmaZoneData_state(true))
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        fetchKarmaZoneData()
    }, []);

    return (
        <>
            {configs.syncKarmaZoneData === true ? (
                <Button
                    onClick={handleClick}
                    size="medium"
                    variant="outlined"
                    color="error"
                    fullWidth
                >
                    暫停同步
                </Button>
            ) : (
                <Button
                    onClick={handleClick}
                    size="medium"
                    variant="outlined"
                    color="warning"
                    fullWidth
                >
                    開始同步
                </Button>
            )}
        </>
    );
}

export default SyncKarmaZoneDataBtn;
