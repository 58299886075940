import { useAppSelector } from '../app/hooks';

export default function useMapPlayerName() {

    const configs = useAppSelector(state => state.baseballAppReducer.config);

    function handleMapPlayerName(playerPosition: string) {
        if (configs.defenseTeamName === configs.guestTeamName) {
            for (let index = 0; index < configs.guestLineup.length; index++) {
                if (configs.guestLineup[index].守位 === playerPosition) {
                    return configs.guestLineup[index].姓名
                }
            }
        } else {
            for (let index = 0; index < configs.homeLineup.length; index++) {
                if (configs.homeLineup[index].守位 === playerPosition) {
                    return configs.homeLineup[index].姓名
                }
            }
        }
    }

    return {
        handleMapPlayerName
    };

}