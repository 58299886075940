import { useAppDispatch, useAppSelector } from '../app/hooks';
import { set_playtime_state } from '../slices/baseballApp';

import Button from '@mui/material/Button';

const MarkOut = () => {

    const records = useAppSelector(state => state.baseballAppReducer.records);
    let dispatch = useAppDispatch()

    let playtimeHandleClick = () => {
        const current = new Date();
        const time = `${current.getTime()}`;
        dispatch(set_playtime_state(time))
    }

    return (
        <>
            {records.playtime === "" ? (
                <Button onClick={playtimeHandleClick} size="large" fullWidth variant="outlined" color="inherit">mark out</Button>
            ) : (
                <Button onClick={playtimeHandleClick} size="large" fullWidth variant="contained" color="inherit">mark out</Button>
            )}
        </>
    );
}

export default MarkOut;
