import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_結果_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const OutState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const outState = records.結果;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={outState} onClick={(event) => { dispatch(set_結果_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="刺殺" control={<Radio size="small" color="primary" />} label="刺殺" />
                <FormControlLabel style={style} value="接殺" control={<Radio size="small" color="primary" />} label="接殺" />
                <FormControlLabel style={style} value="美技" control={<Radio size="small" color="primary" />} label="美技" />
                <FormControlLabel style={style} value="雙殺" control={<Radio size="small" color="primary" />} label="雙殺" />
                <FormControlLabel style={style} value="三殺" control={<Radio size="small" color="primary" />} label="三殺" />
                <FormControlLabel style={style} value="平飛被接" control={<Radio size="small" color="primary" />} label="平飛被接" />
                <FormControlLabel style={style} value="內飛必死" control={<Radio size="small" color="primary" />} label="內飛必死" />
                <FormControlLabel style={style} value="觸擊失敗" control={<Radio size="small" color="primary" />} label="觸擊失敗" />
            </RadioGroup>
        </FormControl>
    );
}

export default OutState;
