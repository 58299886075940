import React from 'react';

import ReactImageDot from './components/ReactImageDot';
import DotsInfo from './components/DotsInfo';
import styles from './css/styles.css';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { set_打擊落點_x_state, set_打擊落點_y_state, set_fieldZoneBox_state } from '../../slices/baseballApp';

const FieldZoneImageDot = () => {

  const configs = useAppSelector(state => state.baseballAppReducer.config);
  const dispatch = useAppDispatch();
  
  const addDot = (dot) => {
    dispatch(set_fieldZoneBox_state(dot));
    dispatch(set_打擊落點_x_state(dot.x));
    dispatch(set_打擊落點_y_state(dot.y));
    // this.setState({
    //   dots: [
    //     // ...this.state.dots,
    //     dot,
    //   ],
    // });
  }

  // const deleteDot = (index) => {
  //   this.setState({
  //     dots: this.state.dots.filter((e, i) => {
  //       return i !== index;
  //     }),
  //   });
  // }

  // const resetDots = () => {
  //   this.setState({
  //     dots: this.initialDots,
  //   });
  // }


  return (
    <div>
      <ReactImageDot
        backgroundImageUrl='https://upload.cc/i1/2022/03/16/FnzITY.png'
        width={480}
        height={385}
        dots={configs.fieldZoneBox}
        // deleteDot={deleteDot}
        addDot={addDot}
        dotRadius={6}
        dotStyles={{
          backgroundColor: 'blue',
          boxShadow: '0 2px 4px gray',
        }}
        backgroundSize={'cover'}
      />
      {/* <button onClick={this.resetDots}>Reset</button> */}
      {/* <DotsInfo
        height={480}
        width={480}
        dots={configs.fieldZoneBox}
        deleteDot={deleteDot}
      /> */}
    </div>
  );

}

export default FieldZoneImageDot;