import { createSlice } from '@reduxjs/toolkit';
import Airtable from 'airtable';

interface LineUp {
    隊伍: string
    姓名: string
    背號: number
    打序: number | any
    守位: number | any
    慣用手: string
};

interface Referees {
    主審姓名: string
    一壘審姓名: string
    二壘審姓名: string
    三壘審姓名: string
    左線審姓名: string
    右線審姓名: string
};

interface InitialState {
    records: {
        總球數: string,
        日期: string,
        比賽: string,
        場次: string,
        主審: string,
        場地: string,
        局數: string,
        主隊得分: string,
        客隊得分: string,
        投手: string,
        投手用手: string,
        投手隊伍: string,
        捕手: string,
        投手球數: string,
        好球數: string,
        壞球數: string,
        出局數: string,
        球速: string,
        球種: string,
        打者: string,
        打者棒次: string,
        打者用手: string,
        打者守位: string,
        揮棒: string,
        進壘點_x: string,
        進壘點_y: string,
        進壘點資料狀態: string,
        好壞球: string,
        結果: string,
        打擊落點_x: string,
        打擊落點_y: string,
        一接: string,
        二接: string,
        三接: string,
        四接: string,
        傳失誤: string,
        接失誤: string,
        事件: string,
        一壘跑者: string,
        二壘跑者: string,
        三壘跑者: string,
        得分者: string,
        失分: string,
        自責分: string,
        一出局: string,
        二出局: string,
        三出局: string,
        出局: string,
        本一時間: string,
        打席: string,
        打點: string,
        好球: string,
        壞球: string,
        sb: string,
        出局者: string,
        前好球: string,
        前壞球: string,
        count: string,
        在壘與出局: string,
        強中弱: string,
        滾飛平: string,
        mark_in: string,
        playtime: string,
        clip_id: string,
        v_move: string,
        h_move: string,
        spin_r: string,
        axis: string,
        efficiency: string,
        exit_velo: string,
        launch_angle: string,
        direction: string,
    },
    config: {
        base_id: string,
        gameDate: string,
        gameTime: string,
        gameNumber: string,
        guestTeamName: string,
        homeTeamName: string,
        guestLineup: Array<LineUp>,
        homeLineup: Array<LineUp>,
        guestRoster: Array<LineUp>,
        homeRoster: Array<LineUp>,
        referees: Array<Referees>,
        karmaZoneHost: string,
        attackTeamName: string,
        defenseTeamName: string,
        inning: number,
        inningType: string,
        homeBattingOrder: number,
        guestBattingOrder: number,
        strikeState: number,
        ballState: number,
        outState: number,
        homeR: number,
        homeH: number,
        homeE: number,
        guestR: number,
        guestH: number,
        guestE: number,
        onceCatchState: number,
        twiceCatchState: number,
        thriceCatchState: number,
        fourCatchState: number,
        passErrState: number,
        catchErrState: number,
        firstBaseState: any,
        secondBaseState: any,
        thirdBaseState: any,
        homePlateState: any,
        oneScoreState: any,
        twoScoreState: any,
        threeScoreState: any,
        fourScoreState: any,
        guestScoreBox: Array<string>,
        homeScoreBox: Array<string>,
        kZoneBox: Array<string>,
        fieldZoneBox: Array<string>,
        guestInPlayPitcherName: string,
        guestInPlayPitcherNumber: number,
        guestInPlayBatterName: string,
        guestInPlayBatterNumber: number,
        guestInPlayCatcherName: string,
        guestInPlayCatcherNumber: number,
        homeInPlayPitcherName: string,
        homeInPlayPitcherNumber: number,
        homeInPlayBatterName: string,
        homeInPlayBatterNumber: number,
        homeInPlayCatcherName: string,
        homeInPlayCatcherNumber: number,
        checkBaseStateDialogOpen: boolean,
        checkEarnedRunStateDialogOpen: boolean,
        changeInningMsgBoxPop: boolean,
        firstEarnedRun: any,
        secondEarnedRun: any,
        thirdEarnedRun: any,
        fourthEarnedRun: any,
        firstEarnedRunToggleButtonValue: any,
        secondEarnedRunToggleButtonValue: any,
        thirdEarnedRunToggleButtonValue: any,
        fourthEarnedRunToggleButtonValue: any,
        subInningDialogOpen: boolean,
        snackbar_checkBaseDialogAlert: boolean,
        syncKarmaZoneData: boolean,
        allKarmaZoneData: any,
        lastBatterName: string,
        guestLastPitcherName: string,
        homeLastPitcherName: string,
        guestPitchCount: number,
        homePitchCount: number,
        guestStrikePitchCount: number,
        homeStrikePitchCount: number,
        guestBallPitchCount: number,
        homeBallPitchCount: number,
        homeInPlayPitchCount: number,
        guestInPlayPitchCount: number,
        homeTotalPitchCount: number,
        guestTotalPitchCount: number
    }
};

// 定義初始值
const initialState: InitialState = {
    records: {
        總球數: "0",
        日期: "",
        比賽: "",
        場次: "",
        主審: "",
        場地: "",
        局數: "",
        主隊得分: "0",
        客隊得分: "0",
        投手: "",
        投手用手: "",
        投手隊伍: "",
        捕手: "",
        投手球數: "0",
        好球數: "0",
        壞球數: "0",
        出局數: "0",
        球速: "",
        球種: "速球",
        打者: "",
        打者棒次: "",
        打者用手: "",
        打者守位: "",
        揮棒: "未出棒",
        進壘點_x: "",
        進壘點_y: "",
        進壘點資料狀態: "",
        好壞球: "好球",
        結果: "",
        打擊落點_x: "",
        打擊落點_y: "",
        一接: "",
        二接: "",
        三接: "",
        四接: "",
        傳失誤: "",
        接失誤: "",
        事件: "",
        一壘跑者: "",
        二壘跑者: "",
        三壘跑者: "",
        得分者: "",
        失分: "",
        自責分: "",
        一出局: "",
        二出局: "",
        三出局: "",
        出局: "",
        本一時間: "",
        打席: "",
        打點: "",
        好球: "0",
        壞球: "0",
        sb: "",
        出局者: "",
        前好球: "",
        前壞球: "",
        count: "",
        在壘與出局: "",
        強中弱: "",
        滾飛平: "",
        mark_in: "",
        playtime: "",
        clip_id: "",
        v_move: "",
        h_move: "",
        spin_r: "",
        axis: "",
        efficiency: "",
        exit_velo: "",
        launch_angle: "",
        direction: "",
    },
    config: {
        base_id: "",
        gameDate: "",
        gameTime: "",
        gameNumber: "",
        guestTeamName: "",
        homeTeamName: "",
        guestLineup: [],
        homeLineup: [],
        guestRoster: [],
        homeRoster: [],
        referees: [],
        karmaZoneHost: "",
        attackTeamName: "",
        defenseTeamName: "",
        inning: 1,
        inningType: "Top",
        homeBattingOrder: 1,
        guestBattingOrder: 1,
        strikeState: 0,
        ballState: 0,
        outState: 0,
        homeR: 0,
        homeH: 0,
        homeE: 0,
        guestR: 0,
        guestH: 0,
        guestE: 0,
        onceCatchState: 0,
        twiceCatchState: 0,
        thriceCatchState: 0,
        fourCatchState: 0,
        passErrState: 0,
        catchErrState: 0,
        firstBaseState: null,
        secondBaseState: null,
        thirdBaseState: null,
        homePlateState: null,
        oneScoreState: null,
        twoScoreState: null,
        threeScoreState: null,
        fourScoreState: null,
        guestScoreBox: [],
        homeScoreBox: [],
        kZoneBox: [],
        fieldZoneBox: [],
        guestInPlayPitcherName: "",
        guestInPlayPitcherNumber: 0,
        guestInPlayBatterName: "",
        guestInPlayBatterNumber: 0,
        guestInPlayCatcherName: "",
        guestInPlayCatcherNumber: 0,
        homeInPlayPitcherName: "",
        homeInPlayPitcherNumber: 0,
        homeInPlayBatterName: "",
        homeInPlayBatterNumber: 0,
        homeInPlayCatcherName: "",
        homeInPlayCatcherNumber: 0,
        checkBaseStateDialogOpen: false,
        checkEarnedRunStateDialogOpen: false,
        changeInningMsgBoxPop: false,
        firstEarnedRun: null,
        secondEarnedRun: null,
        thirdEarnedRun: null,
        fourthEarnedRun: null,
        firstEarnedRunToggleButtonValue: null,
        secondEarnedRunToggleButtonValue: null,
        thirdEarnedRunToggleButtonValue: null,
        fourthEarnedRunToggleButtonValue: null,
        subInningDialogOpen: false,
        snackbar_checkBaseDialogAlert: false,
        syncKarmaZoneData: true,
        allKarmaZoneData: "",
        guestLastPitcherName: "",
        homeLastPitcherName: "",
        lastBatterName: "",
        guestPitchCount: 0,
        homePitchCount: 0,
        guestStrikePitchCount: 0,
        homeStrikePitchCount: 0,
        guestBallPitchCount: 0,
        homeBallPitchCount: 0,
        homeInPlayPitchCount: 0,
        guestInPlayPitchCount: 0,
        homeTotalPitchCount: 0,
        guestTotalPitchCount: 0
    }

};

export const baseballAppSlice = createSlice({
    name: 'baseballApp',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // 1. state ->  當下的狀態
        // 2. action -> 對應的動作
        set_default_record_state: (state) => {
            // state.records.總球數 = ""
            // state.records.日期 = ""
            // state.records.比賽 = ""
            // state.records.場次 = ""
            // state.records.主審 = ""
            // state.records.場地 = ""
            // state.records.局數 = ""
            // state.records.主隊得分 = ""
            // state.records.客隊得分 = ""
            // state.records.投手 = ""
            // state.records.投手用手 = ""
            // state.records.投手隊伍 = ""
            // state.records.捕手 = ""
            // state.records.投手球數 = ""
            // state.records.好球數 = ""
            // state.records.壞球數 = ""
            // state.records.出局數 = ""
            state.records.球速 = ""
            state.records.球種 = "速球"
            // state.records.打者 = ""
            // state.records.打者棒次 = ""
            // state.records.打者用手 = ""
            // state.records.打者守位 = ""
            state.records.揮棒 = "未出棒"
            state.records.進壘點_x = ""
            state.records.進壘點_y = ""
            state.records.進壘點資料狀態 = ""
            state.records.好壞球 = "好球"
            state.records.結果 = ""
            state.records.打擊落點_x = ""
            state.records.打擊落點_y = ""
            state.records.一接 = ""
            state.records.二接 = ""
            state.records.三接 = ""
            state.records.四接 = ""
            state.records.傳失誤 = ""
            state.records.接失誤 = ""
            state.records.事件 = ""
            // state.records.一壘跑者 = ""
            // state.records.二壘跑者 = ""
            // state.records.三壘跑者 = ""
            state.records.得分者 = ""
            state.records.失分 = ""
            state.records.自責分 = ""
            state.records.一出局 = ""
            state.records.二出局 = ""
            state.records.三出局 = ""
            state.records.出局 = ""
            state.records.本一時間 = ""
            state.records.打席 = ""
            state.records.打點 = ""
            // state.records.好球 = ""
            // state.records.壞球 = ""
            state.records.sb = ""
            state.records.出局者 = ""
            // state.records.前好球 = ""
            // state.records.前壞球 = ""
            state.records.count = ""
            state.records.在壘與出局 = ""
            state.records.強中弱 = ""
            state.records.滾飛平 = ""
            state.records.mark_in = ""
            state.records.playtime = ""
            state.records.clip_id = ""
            state.records.v_move = ""
            state.records.h_move = ""
            state.records.spin_r = ""
            state.records.axis = ""
            state.records.efficiency = ""
            state.records.exit_velo = ""
            state.records.launch_angle = ""
            state.records.direction = ""

            // config set to default
            state.config.oneScoreState = null
            state.config.twoScoreState = null
            state.config.threeScoreState = null
            state.config.fourScoreState = null
            state.config.homePlateState = null
            state.config.onceCatchState = 0
            state.config.twiceCatchState = 0
            state.config.thriceCatchState = 0
            state.config.fourCatchState = 0
            state.config.passErrState = 0
            state.config.catchErrState = 0
        },
        set_總球數_state: (state, action) => {
            state.records.總球數 = String(action.payload)
        },
        set_日期_state: (state, action) => {
            state.records.日期 = String(action.payload)
        },
        set_比賽_state: (state, action) => {
            state.records.比賽 = String(action.payload)
        },
        set_場次_state: (state, action) => {
            state.records.場次 = String(action.payload)
        },
        set_主審_state: (state, action) => {
            state.records.主審 = String(action.payload)
        },
        set_場地_state: (state, action) => {
            state.records.場地 = String(action.payload)
        },
        set_局數_state: (state, action) => {
            state.records.局數 = String(action.payload)
        },
        set_主隊得分_state: (state, action) => {
            state.records.主隊得分 = String(action.payload)
        },
        set_客隊得分_state: (state, action) => {
            state.records.客隊得分 = String(action.payload)
        },
        set_投手_state: (state, action) => {
            state.records.投手 = String(action.payload)
        },
        set_投手用手_state: (state, action) => {
            state.records.投手用手 = String(action.payload)
        },
        set_投手隊伍_state: (state, action) => {
            state.records.投手隊伍 = String(action.payload)
        },
        set_捕手_state: (state, action) => {
            state.records.捕手 = String(action.payload)
        },
        set_投手球數_state: (state, action) => {
            state.records.投手球數 = String(action.payload)
        },
        set_好球數_state: (state, action) => {
            state.records.好球數 = String(action.payload)
        },
        set_壞球數_state: (state, action) => {
            state.records.壞球數 = String(action.payload)
        },
        set_出局數_state: (state, action) => {
            state.records.出局數 = String(action.payload)
        },
        set_球速_state: (state, action) => {
            state.records.球速 = String(action.payload)
        },
        set_球種_state: (state, action) => {
            if (String(action.payload) === state.records.球種) {
                state.records.球種 = ""
            } else {
                state.records.球種 = String(action.payload)
            }
        },
        set_打者_state: (state, action) => {
            state.records.打者 = String(action.payload)
        },
        set_打者棒次_state: (state, action) => {
            state.records.打者棒次 = String(action.payload)
        },
        set_打者用手_state: (state, action) => {
            state.records.打者用手 = String(action.payload)
        },
        set_打者守位_state: (state, action) => {
            state.records.打者守位 = String(action.payload)
        },
        set_揮棒_state: (state, action) => {
            if (String(action.payload) === state.records.揮棒) {
                state.records.揮棒 = ""
            } else {
                state.records.揮棒 = String(action.payload)
            }
            if (String(action.payload) === "出棒") {
                state.records.好壞球 = "好球"
            }
        },
        set_進壘點_x_state: (state, action) => {
            state.records.進壘點_x = String(action.payload)
        },
        set_進壘點_y_state: (state, action) => {
            state.records.進壘點_y = String(action.payload)
        },
        set_進壘點資料狀態_state: (state, action) => {
            state.records.進壘點資料狀態 = String(action.payload)
        },
        set_好壞球_state: (state, action) => {
            if (String(action.payload) === state.records.好壞球) {
                state.records.好壞球 = ""
            } else {
                state.records.好壞球 = String(action.payload)
            }
        },
        set_結果_state: (state, action) => {
            if (String(action.payload) === state.records.結果) {
                state.records.結果 = ""
            } else {
                state.records.結果 = String(action.payload)
                if (action.payload === '接殺' || action.payload === 'Bunt' || action.payload === '飛犧' || action.payload === '推進' || action.payload === '三殺' || action.payload === '失誤' || action.payload === '刺殺' || action.payload === '一壘安' || action.payload === '二壘安' || action.payload === '三壘安' || action.payload === '全壘打' || action.payload === '野選' || action.payload === '觸身' || action.payload === '敬遠' || action.payload === '不死三振' || action.payload === '妨礙打擊' || action.payload === '夾殺進壘' || action.payload === '妨礙跑壘' || action.payload === '牽制失敗' || action.payload === '守備失敗' || action.payload === '盜二成功' || action.payload === '盜三成功' || action.payload === '盜本成功' || action.payload === '雙盜成功' || action.payload === '盜二出局' || action.payload === '雙盜死一' || action.payload === '牽制出局' || action.payload === '夾殺出局' || action.payload === '盜三出局' || action.payload === '跑壘失誤' || action.payload === '妨礙守備' || action.payload === '盜本出局' || action.payload === '夾殺回壘' || action.payload === '牽制失誤' || action.payload === '守備失誤' || action.payload === '雙盜壘成' || action.payload === '四壞') {
                    state.config.checkBaseStateDialogOpen = true
                }
            }
        },
        set_打擊落點_x_state: (state, action) => {
            state.records.打擊落點_x = String(action.payload)
        },
        set_打擊落點_y_state: (state, action) => {
            state.records.打擊落點_y = String(action.payload)
        },
        set_一接_state: (state, action) => {
            state.records.一接 = String(action.payload)
        },
        set_二接_state: (state, action) => {
            state.records.二接 = String(action.payload)
        },
        set_三接_state: (state, action) => {
            state.records.三接 = String(action.payload)
        },
        set_四接_state: (state, action) => {
            state.records.四接 = String(action.payload)
        },
        set_傳失誤_state: (state, action) => {
            state.records.傳失誤 = String(action.payload)
        },
        set_接失誤_state: (state, action) => {
            state.records.接失誤 = String(action.payload)
        },
        set_事件_state: (state, action) => {
            if (String(action.payload) === state.records.事件) {
                state.records.事件 = ""
            } else {
                state.records.事件 = String(action.payload)
                if (action.payload === 'Bunt' || action.payload === '飛犧' || action.payload === '推進') {
                    state.config.checkBaseStateDialogOpen = true
                }
            }
        },
        set_一壘跑者_state: (state, action) => {
            state.records.一壘跑者 = String(action.payload)
        },
        set_二壘跑者_state: (state, action) => {
            state.records.二壘跑者 = String(action.payload)
        },
        set_三壘跑者_state: (state, action) => {
            state.records.三壘跑者 = String(action.payload)
        },
        set_得分者_state: (state, action) => {
            state.records.得分者 = String(action.payload)
        },
        set_失分_state: (state, action) => {
            state.records.失分 = String(action.payload)
        },
        set_自責分_state: (state, action) => {
            if (String(action.payload) === state.records.自責分) {
                state.records.自責分 = ""
            } else {
                state.records.自責分 = String(action.payload)
            }
        },
        set_一出局_state: (state, action) => {
            state.records.一出局 = String(action.payload)
        },
        set_二出局_state: (state, action) => {
            state.records.二出局 = String(action.payload)
        },
        set_三出局_state: (state, action) => {
            state.records.三出局 = String(action.payload)
        },
        set_出局_state: (state, action) => {
            state.records.出局 = String(action.payload)
        },
        set_本一時間_state: (state, action) => {
            state.records.本一時間 = String(action.payload)
        },
        set_打席_state: (state, action) => {
            state.records.打席 = String(action.payload)
        },
        set_打點_state: (state, action) => {
            if (String(action.payload) === state.records.打點) {
                state.records.打點 = ""
            } else {
                state.records.打點 = String(action.payload)
            }
        },
        set_好球_state: (state, action) => {
            state.records.好球 = String(action.payload)
        },
        set_壞球_state: (state, action) => {
            state.records.壞球 = String(action.payload)
        },
        set_sb_state: (state, action) => {
            state.records.sb = String(action.payload)
        },
        set_出局者_state: (state, action) => {
            state.records.出局者 = String(action.payload)
        },
        set_前好球_state: (state, action) => {
            state.records.前好球 = String(action.payload)
        },
        set_前壞球_state: (state, action) => {
            state.records.前壞球 = String(action.payload)
        },
        set_count_state: (state, action) => {
            state.records.count = String(action.payload)
        },
        set_在壘與出局_state: (state, action) => {
            state.records.在壘與出局 = String(action.payload)
        },
        set_強中弱_state: (state, action) => {
            if (String(action.payload) === state.records.強中弱) {
                state.records.強中弱 = ""
            } else {
                state.records.強中弱 = String(action.payload)
            }
        },
        set_滾飛平_state: (state, action) => {
            if (String(action.payload) === state.records.滾飛平) {
                state.records.滾飛平 = ""
            } else {
                state.records.滾飛平 = String(action.payload)
            }
        },
        set_mark_in_state: (state, action) => {
            state.records.mark_in = String(action.payload)
        },
        set_playtime_state: (state, action) => {
            state.records.playtime = String(action.payload)
        },
        set_clip_id_state: (state, action) => {
            state.records.clip_id = String(action.payload)
        },
        set_v_move_state: (state, action) => {
            state.records.v_move = String(action.payload)
        },
        set_h_move_state: (state, action) => {
            state.records.h_move = String(action.payload)
        },
        set_spin_r_state: (state, action) => {
            state.records.spin_r = String(action.payload)
        },
        set_axis_state: (state, action) => {
            state.records.axis = String(action.payload)
        },
        set_efficiency_state: (state, action) => {
            state.records.efficiency = String(action.payload)
        },
        set_exit_velo_state: (state, action) => {
            state.records.exit_velo = String(action.payload)
        },
        set_launch_angle_state: (state, action) => {
            state.records.launch_angle = String(action.payload)
        },
        set_direction_state: (state, action) => {
            state.records.direction = String(action.payload)
        },

        // -------- config start --------

        set_base_id_state: (state, action) => {
            state.config.base_id = action.payload
        },
        set_gameDate_state: (state, action) => {
            state.config.gameDate = action.payload
        },
        set_gameTime_state: (state, action) => {
            state.config.gameTime = action.payload
        },
        set_gameNumber_state: (state, action) => {
            state.config.gameNumber = action.payload
        },
        set_guestTeamName_state: (state, action) => {
            state.config.guestTeamName = action.payload
        },
        set_homeTeamName_state: (state, action) => {
            state.config.homeTeamName = action.payload
        },
        set_guestLineup_state: (state, action) => {
            state.config.guestLineup.push(action.payload)
        },
        set_homeLineup_state: (state, action) => {
            state.config.homeLineup.push(action.payload)
        },
        set_guestRoster_state: (state, action) => {
            state.config.guestRoster.push(action.payload)
        },
        set_homeRoster_state: (state, action) => {
            state.config.homeRoster.push(action.payload)
        },
        set_referees_state: (state, action) => {
            state.config.referees = [...state.config.referees.slice(0, 0), action.payload]
        },
        set_KarmaZoneHost_state: (state, action) => {
            state.config.karmaZoneHost = action.payload
        },
        set_attackTeamName_state: (state, action) => {
            state.config.attackTeamName = action.payload
        },
        set_defenseTeamName_state: (state, action) => {
            state.config.defenseTeamName = action.payload
        },
        set_inning_state: (state, action) => {
            state.config.inning = action.payload
        },
        set_inningType_state: (state, action) => {
            state.config.inningType = action.payload
        },
        set_homeBattingOrder_state: (state, action) => {
            state.config.homeBattingOrder = action.payload
        },
        set_guestBattingOrder_state: (state, action) => {
            state.config.guestBattingOrder = action.payload
        },
        set_strikeState_state: (state, action) => {
            state.config.strikeState = action.payload
        },
        set_ballState_state: (state, action) => {
            state.config.ballState = action.payload
        },
        set_outState_state: (state, action) => {
            state.config.outState = action.payload
        },
        set_homeR_state: (state, action) => {
            state.config.homeR = action.payload
        },
        set_homeH_state: (state, action) => {
            state.config.homeH = action.payload
        },
        set_homeE_state: (state, action) => {
            state.config.homeE = action.payload
        },
        set_guestR_state: (state, action) => {
            state.config.guestR = action.payload
        },
        set_guestH_state: (state, action) => {
            state.config.guestH = action.payload
        },
        set_guestE_state: (state, action) => {
            state.config.guestE = action.payload
        },
        set_onceCatchState_state: (state, action) => {
            state.config.onceCatchState = action.payload
        },
        set_twiceCatchState_state: (state, action) => {
            state.config.twiceCatchState = action.payload
        },
        set_thriceCatchState_state: (state, action) => {
            state.config.thriceCatchState = action.payload
        },
        set_fourCatchState_state: (state, action) => {
            state.config.fourCatchState = action.payload
        },
        set_passErrState_state: (state, action) => {
            state.config.passErrState = action.payload
        },
        set_catchErrState_state: (state, action) => {
            state.config.catchErrState = action.payload
        },
        set_firstBaseState_state: (state, action) => {
            state.config.firstBaseState = action.payload
        },
        set_secondBaseState_state: (state, action) => {
            state.config.secondBaseState = action.payload
        },
        set_thirdBaseState_state: (state, action) => {
            state.config.thirdBaseState = action.payload
        },
        set_homePlateState_state: (state, action) => {
            state.config.homePlateState = action.payload
        },
        set_oneScoreState_state: (state, action) => {
            state.config.oneScoreState = action.payload
        },
        set_twoScoreState_state: (state, action) => {
            state.config.twoScoreState = action.payload
        },
        set_threeScoreState_state: (state, action) => {
            state.config.threeScoreState = action.payload
        },
        set_fourScoreState_state: (state, action) => {
            state.config.fourScoreState = action.payload
        },
        set_guestScoreBox_state: (state, action) => {
            state.config.guestScoreBox.push(action.payload)
        },
        set_homeScoreBox_state: (state, action) => {
            state.config.homeScoreBox.push(action.payload)
        },
        set_kZoneBox_state: (state, action) => {
            // state.kZoneBox.push(action.payload)
            state.config.kZoneBox = [...state.config.kZoneBox.slice(0, 0), action.payload]
        },
        set_fieldZoneBox_state: (state, action) => {
            state.config.fieldZoneBox = [...state.config.fieldZoneBox.slice(0, 0), action.payload]
        },
        set_homeInPlayPitcherName_state: (state, action) => {
            state.config.homeInPlayPitcherName = action.payload
            state.config.homeInPlayPitcherNumber = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayPitcherName)[0].背號
        },
        set_homeInPlayBatterName_state: (state, action) => {
            state.config.homeInPlayBatterName = action.payload
            state.config.homeInPlayBatterNumber = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayBatterName)[0].背號
        },
        set_homeInPlayCatcherName_state: (state, action) => {
            state.config.homeInPlayCatcherName = action.payload
            state.config.homeInPlayCatcherNumber = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayCatcherName)[0].背號
        },
        set_guestInPlayPitcherName_state: (state, action) => {
            state.config.guestInPlayPitcherName = action.payload
            state.config.guestInPlayPitcherNumber = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayPitcherName)[0].背號
        },
        set_guestInPlayBatterName_state: (state, action) => {
            state.config.guestInPlayBatterName = action.payload
            state.config.guestInPlayBatterNumber = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayBatterName)[0].背號
        },
        set_guestInPlayCatcherName_state: (state, action) => {
            state.config.guestInPlayCatcherName = action.payload
            state.config.guestInPlayCatcherNumber = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayCatcherName)[0].背號
        },
        set_checkBaseStateDialogOpen_state: (state, action) => {
            state.config.checkBaseStateDialogOpen = action.payload
        },
        set_checkEarnedRunStateDialogOpen_state: (state, action) => {
            state.config.checkEarnedRunStateDialogOpen = action.payload
        },
        set_changeInningMsgBoxPop_state: (state, action) => {
            state.config.changeInningMsgBoxPop = action.payload
        },
        set_firstEarnedRun_state: (state, action) => {
            state.config.firstEarnedRun = action.payload
        },
        set_secondEarnedRun_state: (state, action) => {
            state.config.secondEarnedRun = action.payload
        },
        set_thirdEarnedRun_state: (state, action) => {
            state.config.thirdEarnedRun = action.payload
        },
        set_fourthEarnedRun_state: (state, action) => {
            state.config.fourthEarnedRun = action.payload
        },
        set_firstEarnedRunToggleButtonValue_state: (state, action) => {
            state.config.firstEarnedRunToggleButtonValue = action.payload
        },
        set_secondEarnedRunToggleButtonValue_state: (state, action) => {
            state.config.secondEarnedRunToggleButtonValue = action.payload
        },
        set_thirdEarnedRunToggleButtonValue_state: (state, action) => {
            state.config.thirdEarnedRunToggleButtonValue = action.payload
        },
        set_fourthEarnedRunToggleButtonValue_state: (state, action) => {
            state.config.fourthEarnedRunToggleButtonValue = action.payload
        },
        set_subInningDialogOpen_state: (state, action) => {
            state.config.subInningDialogOpen = action.payload
        },
        set_snackbar_checkBaseDialogAlert_state: (state, action) => {
            state.config.snackbar_checkBaseDialogAlert = action.payload
        },
        set_syncKarmaZoneData_state: (state, action) => {
            state.config.syncKarmaZoneData = action.payload
        },
        set_allKarmaZoneData_state: (state, action) => {
            state.config.allKarmaZoneData = action.payload
        },
        set_guestLastPitcherName_state: (state, action) => {
            state.config.guestLastPitcherName = action.payload
        },
        set_homeLastPitcherName_state: (state, action) => {
            state.config.homeLastPitcherName = action.payload
        },
        set_lastBatterName_state: (state, action) => {
            state.config.lastBatterName = action.payload
        },
        set_guestPitchCount_state: (state, action) => {
            state.config.guestPitchCount = action.payload
        },
        set_homePitchCount_state: (state, action) => {
            state.config.homePitchCount = action.payload
        },
        set_guestStrikePitchCount_state: (state, action) => {
            state.config.guestStrikePitchCount = action.payload
        },
        set_homeStrikePitchCount_state: (state, action) => {
            state.config.homeStrikePitchCount = action.payload
        },
        set_guestBallPitchCount_state: (state, action) => {
            state.config.guestBallPitchCount = action.payload
        },
        set_homeInPlayPitchCount_state: (state, action) => {
            state.config.homeInPlayPitchCount = action.payload
        },
        set_guestInPlayPitchCount_state: (state, action) => {
            state.config.guestInPlayPitchCount = action.payload
        },
        set_homeBallPitchCount_state: (state, action) => {
            state.config.homeBallPitchCount = action.payload
        },
        set_homeTotalPitchCount_state: (state, action) => {
            state.config.homeTotalPitchCount = action.payload
        },
        set_guestTotalPitchCount_state: (state, action) => {
            state.config.guestTotalPitchCount = action.payload
        },

        // -------- config clean start --------

        clean_kZoneBox_state: (state) => {
            state.config.kZoneBox = []
        },
        clean_fieldZoneBox_state: (state) => {
            state.config.fieldZoneBox = []
        },
        clean_guestLineup_state: (state) => {
            state.config.guestLineup = []
        },
        clean_homeLineup_state: (state) => {
            state.config.homeLineup = []
        },
        clean_guestRoster_state: (state) => {
            state.config.guestRoster = []
        },
        clean_homeRoster_state: (state) => {
            state.config.homeRoster = []
        },

        // -------- map config to record start --------
        map_config_to_record: (state) => {
            state.records.局數 = String(state.config.inning)

            // 前好球, 前壞球
            state.records.前好球 = state.records.好球
            state.records.前壞球 = state.records.壞球

            // 當前場上狀況
            state.records.好球 = String(state.config.strikeState)
            state.records.壞球 = String(state.config.ballState)
            state.records.出局數 = String(state.config.outState)

            if (parseInt(state.records.好球) < parseInt(state.records.前好球) || parseInt(state.records.壞球) < parseInt(state.records.前壞球)) {
                state.records.前好球 = String(0)
                state.records.前壞球 = String(0)
            }

            // sb, count
            state.records.sb = String(state.config.strikeState) + ',' + String(state.config.ballState)
            state.records.count = String(state.records.前好球) + '好' + String(state.records.前壞球) + '壞'

            let base_1 = ""
            let base_2 = ""
            let base_3 = ""
            let base_0 = ""

            if (state.records.一壘跑者 !== "") {
                base_1 = "1"
            } else {
                base_1 = ""
            }
            if (state.records.二壘跑者 !== "") {
                base_2 = "2"
            } else {
                base_2 = ""
            }
            if (state.records.三壘跑者 !== "") {
                base_3 = "3"
            } else {
                base_3 = ""
            }
            if (base_1 + base_2 + base_3 === "") {
                base_0 = "0"
            } else {
                base_0 = ""
            }
            state.records.在壘與出局 = base_0 + base_1 + base_2 + base_3 + 'B' + String(state.config.outState) + 'O'

            if (state.records.一出局 !== "") {
                state.records.出局者 = String(state.records.一出局)
            }
            if (state.records.一出局 !== "" && state.records.二出局 !== "") {
                state.records.出局者 = String(state.records.一出局) + '.' + String(state.records.二出局)
            }
            if (state.records.一出局 !== "" && state.records.二出局 !== "" && state.records.三出局 !== "") {
                state.records.出局者 = String(state.records.一出局) + '.' + String(state.records.二出局) + '.' + String(state.records.三出局)
            }

            let totalScore = 0
            if (state.config.oneScoreState !== null) {
                state.records.得分者 = String(state.config.oneScoreState.value)
                totalScore = 1
            }
            if (state.config.oneScoreState !== null && state.config.twoScoreState !== null) {
                state.records.得分者 = String(state.config.oneScoreState.value) + '.' + String(state.config.twoScoreState.value)
                totalScore = 2
            }
            if (state.config.oneScoreState !== null && state.config.twoScoreState !== null && state.config.threeScoreState !== null) {
                state.records.得分者 = String(state.config.oneScoreState.value) + '.' + String(state.config.twoScoreState.value) + '.' + String(state.config.threeScoreState.value)
                totalScore = 3
            }
            if (state.config.oneScoreState !== null && state.config.twoScoreState !== null && state.config.threeScoreState !== null && state.config.fourScoreState !== null) {
                state.records.得分者 = String(state.config.oneScoreState.value) + '.' + String(state.config.twoScoreState.value) + '.' + String(state.config.threeScoreState.value) + '.' + String(state.config.fourScoreState.value)
                totalScore = 4
            }

            if (state.config.attackTeamName === state.config.guestTeamName) {

                state.records.客隊得分 = String(totalScore + parseInt(state.records.客隊得分))
                state.config.guestR = parseInt(state.records.客隊得分)
                if (state.records.結果 === "一壘安" || state.records.結果 === "二壘安" || state.records.結果 === "三壘安" || state.records.結果 === "全壘打") {
                    state.config.guestH += 1
                }
                if (state.records.結果 === "失誤" || state.records.結果 === "跑壘失誤") {
                    state.config.guestE += 1
                }
                if (state.records.結果 === "守備失誤" || state.records.結果 === "牽制失誤") {
                    state.config.homeE += 1
                }

                state.records.投手 = String(state.config.homeInPlayPitcherName)
                state.records.投手用手 = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayPitcherName)[0].慣用手
                state.records.投手隊伍 = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayPitcherName)[0].隊伍
                state.records.捕手 = String(state.config.homeInPlayCatcherName)

                state.records.打者 = String(state.config.guestInPlayBatterName)
                state.records.打者棒次 = String(state.config.guestBattingOrder)
                state.records.打者用手 = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayBatterName)[0].慣用手
                state.records.打者守位 = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayBatterName)[0].守位

                // 前好球, 前壞球
                if (state.config.guestInPlayBatterName !== state.config.lastBatterName) {
                    state.records.前好球 = String(0)
                    state.records.前壞球 = String(0)
                    state.config.lastBatterName = String(state.config.guestInPlayBatterName)
                }

                // 全部投手加總
                state.records.總球數 = String(state.config.homeTotalPitchCount += 1)

                // 單一投手加總
                if (state.records.結果 !== "牽制") {
                    state.records.投手球數 = String(state.config.homeInPlayPitchCount += 1)
                }
                state.records.好球數 = String(state.config.homeStrikePitchCount)
                state.records.壞球數 = String(state.config.homeBallPitchCount)

            } else {

                state.records.主隊得分 = String(totalScore + parseInt(state.records.主隊得分))
                state.config.homeR = parseInt(state.records.主隊得分)
                if (state.records.結果 === "一壘安" || state.records.結果 === "二壘安" || state.records.結果 === "三壘安" || state.records.結果 === "全壘打") {
                    state.config.homeH += 1
                }
                if (state.records.結果 === "失誤" || state.records.結果 === "跑壘失誤") {
                    state.config.homeE += 1
                }
                if (state.records.結果 === "守備失誤" || state.records.結果 === "牽制失誤") {
                    state.config.guestE += 1
                }

                state.records.投手 = String(state.config.guestInPlayPitcherName)
                state.records.投手用手 = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayPitcherName)[0].慣用手
                state.records.投手隊伍 = state.config.guestRoster.filter(player => player.姓名 === state.config.guestInPlayPitcherName)[0].隊伍
                state.records.捕手 = String(state.config.guestInPlayCatcherName)

                state.records.打者 = String(state.config.homeInPlayBatterName)
                state.records.打者棒次 = String(state.config.homeBattingOrder)
                state.records.打者用手 = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayBatterName)[0].慣用手
                state.records.打者守位 = state.config.homeRoster.filter(player => player.姓名 === state.config.homeInPlayBatterName)[0].守位

                // 前好球, 前壞球
                if (state.config.homeInPlayBatterName !== state.config.lastBatterName) {
                    state.records.前好球 = String(0)
                    state.records.前壞球 = String(0)
                    state.config.lastBatterName = String(state.config.homeInPlayBatterName)
                }

                // 全部投手加總
                state.records.總球數 = String(state.config.guestTotalPitchCount += 1)

                // 單一投手加總
                if (state.records.結果 !== "牽制") {
                    state.records.投手球數 = String(state.config.guestInPlayPitchCount += 1)
                }
                state.records.好球數 = String(state.config.guestStrikePitchCount)
                state.records.壞球數 = String(state.config.guestBallPitchCount)

            }
        },

        // -------- store record start --------
        store_record_to_airtable: (state) => {
            const recordBase = new Airtable({ apiKey: 'keya2Fq5zTPhOH0Ld' }).base(state.config.base_id);
            recordBase('raw_data').create(
                { ...state.records },
                function (err: any, record: any) {
                    if (err) {
                        console.error('raw_data', err);
                        return;
                    }
                    console.log('raw_data', record.getId())
                }
            );
            recordBase('HomeAttackPlay-by-Play').create(
                {
                    "總球數": state.records.總球數,
                    "日期": state.records.日期,
                    "比賽": state.records.比賽,
                    "場次": state.records.場次,
                    "主審": state.records.主審,
                    "場地": state.records.場地,
                    "局數": state.records.局數,
                    "主隊得分": state.records.主隊得分,
                    "客隊得分": state.records.客隊得分,
                    "投手": state.records.投手,
                    "投手用手": state.records.投手用手,
                    "投手隊伍": state.records.投手隊伍,
                    "捕手": state.records.捕手,
                    "投手球數": state.records.投手球數,
                    "好球數": state.records.好球數,
                    "壞球數": state.records.壞球數,
                    "出局數": state.records.出局數,
                    "球速": state.records.球速,
                    "球種": state.records.球種,
                    "打者": state.records.打者,
                    "打者棒次": state.records.打者棒次,
                    "打者用手": state.records.打者用手,
                    "打者守位": state.records.打者守位,
                    "揮棒": state.records.揮棒,
                    "進壘點X": state.records.進壘點_x,
                    "進壘點Y": state.records.進壘點_y,
                    "進壘點資料狀態": state.records.進壘點資料狀態,
                    "好壞球": state.records.好壞球,
                    "結果": state.records.結果,
                    "打擊落點X": state.records.打擊落點_x,
                    "打擊落點Y": state.records.打擊落點_y,
                    "一接": state.records.一接,
                    "二接": state.records.二接,
                    "三接": state.records.三接,
                    "四接": state.records.四接,
                    "傳失誤": state.records.傳失誤,
                    "接失誤": state.records.接失誤,
                    "事件": state.records.事件,
                    "一壘跑者": state.records.一壘跑者,
                    "二壘跑者": state.records.二壘跑者,
                    "三壘跑者": state.records.三壘跑者,
                    "得分者": state.records.得分者,
                    "失分": state.records.失分,
                    "自責分": state.records.自責分,
                    "一出局": state.records.一出局,
                    "二出局": state.records.二出局,
                    "三出局": state.records.三出局,
                    "出局": state.records.出局,
                    "本一時間": state.records.本一時間,
                    "打席": state.records.打席,
                    "打點": state.records.打點,
                    "好球": state.records.好球,
                    "壞球": state.records.壞球,
                    "S,B": state.records.sb,
                    "出局者": state.records.出局者,
                    "前好球": state.records.前好球,
                    "前壞球": state.records.前壞球,
                    "Count": state.records.count,
                    "在壘與出局": state.records.在壘與出局,
                    "強中弱": state.records.強中弱,
                    "滾飛平": state.records.滾飛平,
                    "Mark In": state.records.mark_in,
                    "playtime": state.records.playtime,
                    "Clip_ID": state.records.clip_id,
                    "V-Move": state.records.v_move,
                    "H-Move": state.records.h_move,
                    "Spin-R": state.records.spin_r,
                    "Axis": state.records.axis,
                    "Efficiency": state.records.efficiency,
                    "ExitVelo": state.records.exit_velo,
                    "LaunchAngle": state.records.launch_angle,
                    "Direction": state.records.direction,
                },
                function (err: any, record: any) {
                    if (err) {
                        console.error('HomeAttackPlay-by-Play', err);
                        return;
                    }
                    console.log('HomeAttackPlay-by-Play', record.getId())
                }
            );
        },

        // -------- check last pitch game state start --------
        check_last_pitch_game_state: (state) => {

            function handleBatterName(order: number) {
                if (state.config.attackTeamName === state.config.guestTeamName) {
                    for (let index = 0; index < state.config.guestRoster.length; index++) {
                        if (state.config.guestRoster[index].打序 === String(order)) {
                            state.records.打者 = state.config.guestRoster[index].姓名
                            state.config.guestInPlayBatterName = state.config.guestRoster[index].姓名
                        }
                    }
                } else {
                    for (let index = 0; index < state.config.homeRoster.length; index++) {
                        if (state.config.homeRoster[index].打序 === String(order)) {
                            state.records.打者 = state.config.homeRoster[index].姓名
                            state.config.homeInPlayBatterName = state.config.homeRoster[index].姓名
                        }
                    }
                }
            }

            function handleAddBatterOrder() {
                if (state.config.attackTeamName === state.config.guestTeamName) {
                    if (state.config.guestBattingOrder === 9) {
                        state.config.guestBattingOrder = 1
                        handleBatterName(1)
                    } else {
                        state.config.guestBattingOrder += 1
                        handleBatterName(state.config.guestBattingOrder)
                    }
                } else {
                    if (state.config.homeBattingOrder === 9) {
                        state.config.homeBattingOrder = 1
                        handleBatterName(1)
                    } else {
                        state.config.homeBattingOrder += 1
                        handleBatterName(state.config.homeBattingOrder)
                    }
                }
            }

            if (state.config.strikeState === 3) {
                state.config.strikeState = 0
                state.config.ballState = 0
                state.config.outState += 1
                handleAddBatterOrder()
            }
            if (state.config.ballState === 4) {
                state.config.strikeState = 0
                state.config.ballState = 0
                handleAddBatterOrder()
            }
            if (state.config.outState === 3) { // Change Inning if 3 outs
                state.config.changeInningMsgBoxPop = true
            }
        },

        // -------- set score runner state start --------
        set_score_runner_state: (state) => {
            if (state.config.oneScoreState === null && state.config.twoScoreState === null && state.config.threeScoreState === null && state.config.fourScoreState === null) {
                state.config.oneScoreState = state.config.homePlateState
            } else if (state.config.oneScoreState !== null && state.config.twoScoreState === null && state.config.threeScoreState === null && state.config.fourScoreState === null) {
                state.config.twoScoreState = state.config.homePlateState
            } else if (state.config.oneScoreState !== null && state.config.twoScoreState !== null && state.config.threeScoreState === null && state.config.fourScoreState === null) {
                state.config.threeScoreState = state.config.homePlateState
            } else if (state.config.oneScoreState !== null && state.config.twoScoreState !== null && state.config.threeScoreState !== null && state.config.fourScoreState === null) {
                state.config.fourScoreState = state.config.homePlateState
            }
        },

        // -------- check homeplate stete start --------
        check_homeplate_state: (state) => {
            if (state.config.homePlateState === null) {
                state.config.checkBaseStateDialogOpen = false
                state.config.snackbar_checkBaseDialogAlert = false
            } else {
                state.config.snackbar_checkBaseDialogAlert = true
            }
        },
    }
});

export const {
    set_default_record_state,
    set_總球數_state,
    set_日期_state,
    set_比賽_state,
    set_場次_state,
    set_主審_state,
    set_場地_state,
    set_局數_state,
    set_主隊得分_state,
    set_客隊得分_state,
    set_投手_state,
    set_投手用手_state,
    set_投手隊伍_state,
    set_捕手_state,
    set_投手球數_state,
    set_好球數_state,
    set_壞球數_state,
    set_出局數_state,
    set_球速_state,
    set_球種_state,
    set_打者_state,
    set_打者棒次_state,
    set_打者用手_state,
    set_打者守位_state,
    set_揮棒_state,
    set_進壘點_x_state,
    set_進壘點_y_state,
    set_進壘點資料狀態_state,
    set_好壞球_state,
    set_結果_state,
    set_打擊落點_x_state,
    set_打擊落點_y_state,
    set_一接_state,
    set_二接_state,
    set_三接_state,
    set_四接_state,
    set_傳失誤_state,
    set_接失誤_state,
    set_事件_state,
    set_一壘跑者_state,
    set_二壘跑者_state,
    set_三壘跑者_state,
    set_得分者_state,
    set_失分_state,
    set_自責分_state,
    set_一出局_state,
    set_二出局_state,
    set_三出局_state,
    set_出局_state,
    set_本一時間_state,
    set_打席_state,
    set_打點_state,
    set_好球_state,
    set_壞球_state,
    set_sb_state,
    set_出局者_state,
    set_前好球_state,
    set_前壞球_state,
    set_count_state,
    set_在壘與出局_state,
    set_強中弱_state,
    set_滾飛平_state,
    set_mark_in_state,
    set_playtime_state,
    set_clip_id_state,
    set_v_move_state,
    set_h_move_state,
    set_spin_r_state,
    set_axis_state,
    set_efficiency_state,
    set_exit_velo_state,
    set_launch_angle_state,
    set_direction_state,
    // -------- config start --------
    set_base_id_state,
    set_gameDate_state,
    set_gameTime_state,
    set_gameNumber_state,
    set_guestTeamName_state,
    set_homeTeamName_state,
    set_guestLineup_state,
    set_homeLineup_state,
    set_guestRoster_state,
    set_homeRoster_state,
    set_referees_state,
    set_KarmaZoneHost_state,
    set_attackTeamName_state,
    set_defenseTeamName_state,
    set_inning_state,
    set_inningType_state,
    set_homeBattingOrder_state,
    set_guestBattingOrder_state,
    set_strikeState_state,
    set_ballState_state,
    set_outState_state,
    set_homeR_state,
    set_homeH_state,
    set_homeE_state,
    set_guestR_state,
    set_guestH_state,
    set_guestE_state,
    set_onceCatchState_state,
    set_twiceCatchState_state,
    set_thriceCatchState_state,
    set_fourCatchState_state,
    set_passErrState_state,
    set_catchErrState_state,
    set_firstBaseState_state,
    set_secondBaseState_state,
    set_thirdBaseState_state,
    set_homePlateState_state,
    set_oneScoreState_state,
    set_twoScoreState_state,
    set_threeScoreState_state,
    set_fourScoreState_state,
    set_guestScoreBox_state,
    set_homeScoreBox_state,
    set_kZoneBox_state,
    set_fieldZoneBox_state,
    set_homeInPlayPitcherName_state,
    set_homeInPlayBatterName_state,
    set_homeInPlayCatcherName_state,
    set_guestInPlayPitcherName_state,
    set_guestInPlayBatterName_state,
    set_guestInPlayCatcherName_state,
    set_checkBaseStateDialogOpen_state,
    set_checkEarnedRunStateDialogOpen_state,
    set_subInningDialogOpen_state,
    set_snackbar_checkBaseDialogAlert_state,
    set_changeInningMsgBoxPop_state,
    set_firstEarnedRun_state,
    set_secondEarnedRun_state,
    set_thirdEarnedRun_state,
    set_fourthEarnedRun_state,
    set_firstEarnedRunToggleButtonValue_state,
    set_secondEarnedRunToggleButtonValue_state,
    set_thirdEarnedRunToggleButtonValue_state,
    set_fourthEarnedRunToggleButtonValue_state,
    set_syncKarmaZoneData_state,
    set_allKarmaZoneData_state,
    set_guestLastPitcherName_state,
    set_homeLastPitcherName_state,
    set_lastBatterName_state,
    set_guestPitchCount_state,
    set_homePitchCount_state,
    set_guestStrikePitchCount_state,
    set_homeStrikePitchCount_state,
    set_guestBallPitchCount_state,
    set_homeBallPitchCount_state,
    set_homeInPlayPitchCount_state,
    set_guestInPlayPitchCount_state,
    set_homeTotalPitchCount_state,
    set_guestTotalPitchCount_state,
    // -------- config clean start --------
    clean_kZoneBox_state,
    clean_fieldZoneBox_state,
    clean_guestLineup_state,
    clean_homeLineup_state,
    clean_guestRoster_state,
    clean_homeRoster_state,
    // -------- store record start --------
    map_config_to_record,
    // -------- store record start --------
    store_record_to_airtable,
    // -------- check last pitch game state start --------
    check_last_pitch_game_state,
    // -------- set score runner state start --------
    set_score_runner_state,
    // -------- check homeplate stete start --------
    check_homeplate_state,

} = baseballAppSlice.actions;

export default baseballAppSlice.reducer;
