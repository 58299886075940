import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from '../app/hooks';

import Box from '@mui/material/Box';
import Select from "react-select";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
    check_homeplate_state, set_outState_state, set_snackbar_checkBaseDialogAlert_state,
    set_firstBaseState_state, set_secondBaseState_state, set_thirdBaseState_state, set_homePlateState_state,
    set_一壘跑者_state, set_三壘跑者_state, set_二壘跑者_state,
    set_一出局_state, set_二出局_state, set_三出局_state, set_出局_state, set_score_runner_state
} from "../slices/baseballApp";

export default function useCheckBaseStateDialog() {

    interface RosterOption {
        value: string
        label: string
    }

    const [options, setOptions] = useState<RosterOption[]>([]);
    const [snackbarOpenState, setSnackbarOpenState] = useState<boolean>(false);

    const dispatch = useAppDispatch()
    // const records = useAppSelector(state => state.baseballAppReducer.records);
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    useEffect(() => {
        if (configs.attackTeamName === configs.guestTeamName) {
            const option = configs.guestRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)

            dispatch(set_一壘跑者_state(""))
            dispatch(set_二壘跑者_state(""))
            dispatch(set_三壘跑者_state(""))
            dispatch(set_firstBaseState_state(null))
            dispatch(set_secondBaseState_state(null))
            dispatch(set_thirdBaseState_state(null))
            dispatch(set_homePlateState_state(null))
        } else {
            const option = configs.homeRoster.map((data, index) => ({
                value: data.姓名,
                label: data.背號 + " " + data.姓名,
            }));
            setOptions(option)

            dispatch(set_一壘跑者_state(""))
            dispatch(set_二壘跑者_state(""))
            dispatch(set_三壘跑者_state(""))
            dispatch(set_firstBaseState_state(null))
            dispatch(set_secondBaseState_state(null))
            dispatch(set_thirdBaseState_state(null))
            dispatch(set_homePlateState_state(null))
        }
    }, [configs.attackTeamName])

    function handleCheckBaseStateDialog() {

        const handleCheck = () => {
            dispatch(check_homeplate_state())
        };

        const handleClose = () => {
            setSnackbarOpenState(false);
            dispatch(set_snackbar_checkBaseDialogAlert_state(false))
        };

        const handleMoveToSecondBase = () => {
            if (configs.secondBaseState === null) {
                dispatch(set_firstBaseState_state(null))
                dispatch(set_一壘跑者_state(""))

                dispatch(set_secondBaseState_state(configs.firstBaseState))
                dispatch(set_二壘跑者_state(configs.firstBaseState.value))
            } else {
                setSnackbarOpenState(true);
            }
        }

        const handleMoveToThirdBase = (baseNumber: Number) => {
            if (configs.thirdBaseState === null) {
                switch (baseNumber) {
                    case 1:
                        dispatch(set_firstBaseState_state(null))
                        dispatch(set_一壘跑者_state(""))

                        dispatch(set_thirdBaseState_state(configs.firstBaseState))
                        dispatch(set_三壘跑者_state(configs.firstBaseState.value))
                        break;
                    case 2:
                        dispatch(set_secondBaseState_state(null))
                        dispatch(set_二壘跑者_state(""))

                        dispatch(set_thirdBaseState_state(configs.secondBaseState))
                        dispatch(set_三壘跑者_state(configs.secondBaseState.value))
                        break;

                    default:
                        break;
                }
            } else {
                setSnackbarOpenState(true);
            }
        }

        const handleMoveToHomePlate = (baseNumber: Number) => {
            if (configs.homePlateState === null) {
                switch (baseNumber) {
                    case 1:
                        dispatch(set_firstBaseState_state(null))
                        dispatch(set_一壘跑者_state(""))

                        dispatch(set_homePlateState_state(configs.firstBaseState))
                        break;
                    case 2:
                        dispatch(set_secondBaseState_state(null))
                        dispatch(set_二壘跑者_state(""))

                        dispatch(set_homePlateState_state(configs.secondBaseState))
                        break;
                    case 3:
                        dispatch(set_thirdBaseState_state(null))
                        dispatch(set_三壘跑者_state(""))

                        dispatch(set_homePlateState_state(configs.thirdBaseState))
                        break;

                    default:
                        break;
                }
            } else {
                setSnackbarOpenState(true);
            }
        }

        const handleOut = (baseNumber: Number) => {
            switch (baseNumber) {
                case 1:
                    dispatch(set_firstBaseState_state(null))
                    dispatch(set_一壘跑者_state(""))

                    outRunner(configs.firstBaseState.value)
                    break;
                case 2:
                    dispatch(set_secondBaseState_state(null))
                    dispatch(set_二壘跑者_state(""))

                    outRunner(configs.secondBaseState.value)
                    break;
                case 3:
                    dispatch(set_thirdBaseState_state(null))
                    dispatch(set_三壘跑者_state(""))

                    outRunner(configs.thirdBaseState.value)
                    break;
                case 4:
                    dispatch(set_homePlateState_state(null))

                    outRunner(configs.homePlateState.value)
                    break;

                default:
                    break;
            }
        }

        const outRunner = (runnerName: string) => {
            switch (configs.outState) {
                case 0:
                    dispatch(set_一出局_state(runnerName))
                    dispatch(set_outState_state(configs.outState + 1))
                    dispatch(set_出局_state(1))
                    break;
                case 1:
                    dispatch(set_二出局_state(runnerName))
                    dispatch(set_outState_state(configs.outState + 1))
                    dispatch(set_出局_state(1))
                    break;
                case 2:
                    dispatch(set_三出局_state(runnerName))
                    dispatch(set_outState_state(configs.outState + 1))
                    dispatch(set_出局_state(1))
                    break;

                default:
                    break;
            }
        }

        const handleCleanBase = (baseNumber: Number) => {
            switch (baseNumber) {
                case 1:
                    dispatch(set_firstBaseState_state(null))
                    dispatch(set_一壘跑者_state(""))
                    break;
                case 2:
                    dispatch(set_secondBaseState_state(null))
                    dispatch(set_二壘跑者_state(""))
                    break;
                case 3:
                    dispatch(set_thirdBaseState_state(null))
                    dispatch(set_三壘跑者_state(""))
                    break;
                case 4:
                    dispatch(set_homePlateState_state(null))
                    break;

                default:
                    break;
            }
        }

        const handleAddScore = (baseNumber: Number) => {
            dispatch(set_score_runner_state())
            dispatch(set_homePlateState_state(null))
        }

        return (
            <>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={snackbarOpenState} autoHideDuration={2000} onClose={handleClose}>
                    <Alert severity="warning">操作失敗，請檢查壘上狀態是否允許執行。</Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={configs.snackbar_checkBaseDialogAlert} autoHideDuration={2000} onClose={handleClose}>
                    <Alert severity="error">操作失敗，請選擇回本壘跑者最終結果。</Alert>
                </Snackbar>

                <Dialog open={configs.checkBaseStateDialogOpen} onClose={handleCheck} fullWidth={true}>
                    <DialogTitle>確認壘上跑者</DialogTitle>
                    <DialogContent dividers={true} >

                        <Box sx={{ m: 1 }}>
                            <Select value={configs.firstBaseState} options={options} onChange={(event) => { dispatch(set_一壘跑者_state(event.value)); dispatch(set_firstBaseState_state(event)) }} placeholder="一壘跑者" />
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="secondary" onClick={handleMoveToSecondBase}>二壘</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="secondary" onClick={() => { handleMoveToThirdBase(1) }}>三壘</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="secondary" onClick={() => { handleMoveToHomePlate(1) }}>本壘</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="secondary" onClick={() => { handleOut(1) }}>出局</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="secondary" onClick={() => { handleCleanBase(1) }}>清空一壘跑者</Button>
                        </Box>

                        <Box sx={{ m: 1 }}>
                            <Select value={configs.secondBaseState} options={options} onChange={(event) => { dispatch(set_二壘跑者_state(event.value)); dispatch(set_secondBaseState_state(event)) }} placeholder="二壘跑者" />
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="warning" onClick={() => { handleMoveToThirdBase(2) }}>三壘</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="warning" onClick={() => { handleMoveToHomePlate(2) }}>本壘</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="warning" onClick={() => { handleOut(2) }}>出局</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="warning" onClick={() => { handleCleanBase(2) }}>清空二壘跑者</Button>
                        </Box>

                        <Box sx={{ m: 1 }}>
                            <Select value={configs.thirdBaseState} options={options} onChange={(event) => { dispatch(set_三壘跑者_state(event.value)); dispatch(set_thirdBaseState_state(event)) }} placeholder="三壘跑者" />
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="success" onClick={() => { handleMoveToHomePlate(3) }}>本壘</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="success" onClick={() => { handleOut(3) }}>出局</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="success" onClick={() => { handleCleanBase(3) }}>清空三壘跑者</Button>
                        </Box>

                        <Box sx={{ m: 1 }}>
                            <Select value={configs.homePlateState} options={options} onChange={(event) => { dispatch(set_homePlateState_state(event)) }} placeholder="回本壘跑者" />
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="error" onClick={() => { handleAddScore(4) }}>得分</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="error" onClick={() => { handleOut(4) }}>出局</Button>
                            <Button sx={{ mr: 0.5, mt: 0.5 }} variant="outlined" color="error" onClick={() => { handleCleanBase(4) }}>清空回本壘跑者</Button>
                        </Box>

                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" fullWidth onClick={handleCheck}>確認</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return handleCheckBaseStateDialog;

}
