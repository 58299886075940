import type { RouteObject } from 'react-router-dom';

import LineUp from './pages/lineUp';
import Record from './pages/record';
import NotFound from './pages/notFound';

const routes: RouteObject[] = [
    {
        path: "/",
        element: <LineUp />,
        children: [],
    },
    {
        path: "/record",
        element: <Record />,
        children: [],
    },
    {
        path: "*",
        element: <NotFound />,
        children: [],
    },
];

export default routes;
