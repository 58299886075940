import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_結果_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

const OutBaseEvent = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const outBaseEvent = records.結果;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={outBaseEvent} onClick={(event) => { dispatch(set_結果_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="盜二出局" control={<Radio size="small" color="primary" />} label="盜二出局" />
                <FormControlLabel style={style} value="雙盜死一" control={<Radio size="small" color="primary" />} label="雙盜死一" />
                <FormControlLabel style={style} value="牽制出局" control={<Radio size="small" color="primary" />} label="牽制出局" />
                <FormControlLabel style={style} value="妨礙守備" control={<Radio size="small" color="primary" />} label="妨礙守備" />
                <FormControlLabel style={style} value="夾殺出局" control={<Radio size="small" color="primary" />} label="夾殺出局" />
                <FormControlLabel style={style} value="盜三出局" control={<Radio size="small" color="primary" />} label="盜三出局" />
                <FormControlLabel style={style} value="跑壘失誤" control={<Radio size="small" color="primary" />} label="跑壘失誤" />
                <FormControlLabel style={style} value="盜本出局" control={<Radio size="small" color="primary" />} label="盜本出局" />
            </RadioGroup>
        </FormControl>
    );
}

export default OutBaseEvent;
