import { useAppSelector, useAppDispatch } from '../app/hooks';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
    set_changeInningMsgBoxPop_state, set_inning_state, set_strikeState_state,
    set_ballState_state, set_outState_state, set_attackTeamName_state,
    set_defenseTeamName_state, set_inningType_state
} from "../slices/baseballApp";

export default function useCheckChangeInningDialog() {

    const dispatch = useAppDispatch()
    // const records = useAppSelector(state => state.baseballAppReducer.records);
    const configs = useAppSelector(state => state.baseballAppReducer.config);

    function handleCheckChangeInningDialog() {

        const handleCheck = () => {
            if (configs.inningType === "Bottom") {
                dispatch(set_inning_state(configs.inning + 1))
            }
            if (configs.attackTeamName === configs.guestTeamName) {
                dispatch(set_strikeState_state(0))
                dispatch(set_ballState_state(0))
                dispatch(set_outState_state(0))
                dispatch(set_attackTeamName_state(configs.homeTeamName))
                dispatch(set_defenseTeamName_state(configs.guestTeamName))
                dispatch(set_inningType_state("Bottom"))
            } else {
                dispatch(set_strikeState_state(0))
                dispatch(set_ballState_state(0))
                dispatch(set_outState_state(0))
                dispatch(set_attackTeamName_state(configs.guestTeamName))
                dispatch(set_defenseTeamName_state(configs.homeTeamName))
                dispatch(set_inningType_state("Top"))
            }
            dispatch(set_changeInningMsgBoxPop_state(false))
        };

        const handleCancel = () => {
            dispatch(set_changeInningMsgBoxPop_state(false))
        };

        return (
            <>
                <Dialog open={configs.changeInningMsgBoxPop} fullWidth={true}>
                    <DialogTitle>是否確認要換局</DialogTitle>
                    <DialogActions>
                        <Button variant="outlined" color="success" fullWidth onClick={handleCheck}>確認換局</Button>
                        <Button variant="outlined" color="error" fullWidth onClick={handleCancel}>取消換局</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

    return handleCheckChangeInningDialog;

}
