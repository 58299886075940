import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useAppDispatch } from '../app/hooks';

import useSetLineUp from '../hooks/useSetLineUp';
import useSetGameInfo from '../hooks/useSetGameInfo';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Select from "react-select";
import Button from '@mui/material/Button';

import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

import {
    clean_guestLineup_state, clean_homeLineup_state,
    clean_guestRoster_state, clean_homeRoster_state,
    set_KarmaZoneHost_state, set_base_id_state
} from '../slices/baseballApp';

function LineUpPage() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { guestFinalLineUp, homeFinalLineUp, loadingState,
        setGuestFinalLineUp, setHomeFinalLineUp, setLoadingState,
        handleSetLineup } = useSetLineUp();

    const { allGameInfo, gameInfoLoadingState,
        setAllGameInfo, setGameInfoLoadingState,
        handleSetGameInfo } = useSetGameInfo();

    const [baseId, setBaseId] = useState("");
    const [kzoneField] = useState([
        {
            value: '',
            label: 'KarmaZone Host',
        },
        {
            value: 'DouLiu',
            label: '斗六棒球場',
        },
        {
            value: 'Tainan',
            label: '台南棒球場',
        },
        {
            value: 'Tianmu',
            label: '天母棒球場',
        },
        {
            value: 'XinZhuang',
            label: '新莊棒球場',
        }
    ]);

    const handleClick = () => {
        setLoadingState(true);
        setGameInfoLoadingState(true);
        dispatch(set_base_id_state(baseId));
        handleSetLineup(baseId);
        handleSetGameInfo(baseId);
    }

    useEffect(() => {
        setLoadingState(false);
        setGameInfoLoadingState(false);
        setAllGameInfo([]);
        setGuestFinalLineUp([]);
        setHomeFinalLineUp([]);
        dispatch(clean_guestLineup_state());
        dispatch(clean_homeLineup_state());
        dispatch(clean_guestRoster_state());
        dispatch(clean_homeRoster_state());
    }, [baseId]);

    return (
        <Box
            sx={{
                mx: 'auto',
                width: 'auto',
                p: 1,
                m: 1,
                bgcolor: (theme) =>
                    theme.palette.mode === 'dark' ? '#101010' : 'grey.50',
                color: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.300' : 'grey.800',
                border: '1px solid',
                borderColor: (theme) =>
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: 2,
                textAlign: 'center',
                fontSize: '0.875rem',
                fontWeight: '700',
            }}
        >
            <Grid spacing={2} container>
                <Grid item xs={12} sm={12} md={12}>
                    <TextField onChange={(e) => { setBaseId(e.target.value); }} size="small" fullWidth id="outlined-basic" label="Record Base API Key" variant="outlined" />
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {baseId !== "" ? (
                        <Select onChange={(event?) => { dispatch(set_KarmaZoneHost_state(event?.value)) }} options={kzoneField} isLoading={loadingState} isDisabled={loadingState} placeholder="KarmaZone Host" />
                    ) : (
                        <Select placeholder="KarmaZone Host" isDisabled={true} />
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {baseId !== "" ? (
                        <LoadingButton
                            size="medium"
                            onClick={handleClick}
                            loading={loadingState}
                            loadingIndicator="讀取中"
                            variant="outlined"
                            fullWidth
                        >
                            讀取名單
                        </LoadingButton>
                    ) : (
                        <LoadingButton
                            size="medium"
                            disabled={true}
                            variant="outlined"
                            fullWidth
                        >
                            讀取名單
                        </LoadingButton>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {allGameInfo.length > 0 && homeFinalLineUp.length > 0 && guestFinalLineUp.length > 0 ? (
                        <Button
                            size="medium"
                            variant="outlined"
                            color="success"
                            fullWidth
                            onClick={() => { navigate('/record') }}
                        >
                            開始紀錄
                        </Button>
                    ) : (
                        <Button
                            size="medium"
                            disabled={true}
                            variant="outlined"
                            color="success"
                            fullWidth
                        >
                            開始紀錄
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {allGameInfo.length > 0 ? (
                        <Paper variant="outlined" >
                            <h2>賽事資訊</h2>
                            <TableContainer>
                                <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">賽事日期</TableCell>
                                            <TableCell align="center">賽事時間</TableCell>
                                            <TableCell align="center">賽事地點</TableCell>
                                            <TableCell align="center">賽事天氣</TableCell>
                                            <TableCell align="center">賽事名稱</TableCell>
                                            <TableCell align="center">賽事等級</TableCell>
                                            <TableCell align="center">賽事場次</TableCell>
                                            <TableCell align="center">客隊名稱</TableCell>
                                            <TableCell align="center">主隊名稱</TableCell>
                                            <TableCell align="center">主審姓名</TableCell>
                                            <TableCell align="center">一壘審姓名</TableCell>
                                            <TableCell align="center">二壘審姓名</TableCell>
                                            <TableCell align="center">三壘審姓名</TableCell>
                                            <TableCell align="center">左線審姓名</TableCell>
                                            <TableCell align="center">右線審姓名</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow
                                            key={0}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell align="center">{allGameInfo[0].賽事日期}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].賽事時間}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].賽事地點}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].賽事天氣}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].賽事名稱}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].賽事等級}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].賽事場次}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].客隊名稱}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].主隊名稱}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].主審姓名}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].一壘審姓名}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].二壘審姓名}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].三壘審姓名}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].左線審姓名}</TableCell>
                                            <TableCell align="center">{allGameInfo[0].右線審姓名}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    ) : (
                        <Paper variant="outlined" >
                            <h2>賽事資訊</h2>
                            {gameInfoLoadingState && <Skeleton sx={{ height: 200 }} variant="rectangular" />}
                        </Paper>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {guestFinalLineUp.length > 0 ? (
                        <Paper variant="outlined" >
                            <h2>{guestFinalLineUp[0].隊伍}&nbsp;先發名單</h2>
                            <TableContainer>
                                <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">背號</TableCell>
                                            <TableCell align="center">姓名</TableCell>
                                            <TableCell align="center">打序</TableCell>
                                            <TableCell align="center">守位</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {guestFinalLineUp.map((item: any, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{item.背號}</TableCell>
                                                <TableCell align="center">{item.姓名}</TableCell>
                                                <TableCell align="center">{item.打序}</TableCell>
                                                <TableCell align="center">{item.守位}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    ) : (
                        <Paper variant="outlined" >
                            <h2>客隊&nbsp;先發名單</h2>
                            {loadingState && <Skeleton sx={{ height: 200 }} variant="rectangular" />}
                        </Paper>
                    )}
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    {homeFinalLineUp.length > 0 ? (
                        <Paper variant="outlined" >
                            <h2>{homeFinalLineUp[0].隊伍}&nbsp;先發名單</h2>
                            <TableContainer>
                                <Table sx={{ width: '100%' }} size="small" aria-label="line up table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">背號</TableCell>
                                            <TableCell align="center">姓名</TableCell>
                                            <TableCell align="center">打序</TableCell>
                                            <TableCell align="center">守位</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {homeFinalLineUp.map((item: any, index) => (
                                            <TableRow
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{item.背號}</TableCell>
                                                <TableCell align="center">{item.姓名}</TableCell>
                                                <TableCell align="center">{item.打序}</TableCell>
                                                <TableCell align="center">{item.守位}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                    ) : (
                        <Paper variant="outlined" >
                            <h2>主隊&nbsp;先發名單</h2>
                            {loadingState && <Skeleton sx={{ height: 200 }} variant="rectangular" />}
                        </Paper>
                    )}
                </Grid>
            </Grid >
        </Box>
    );
}

export default LineUpPage;
