import { useState } from "react";
import { useAppDispatch } from '../app/hooks';

import {
    set_checkEarnedRunStateDialogOpen_state, set_失分_state, set_自責分_state
} from "../slices/baseballApp";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

function EarnedRunState() {

    const dispatch = useAppDispatch();
    const [earnedRunState, setEarnedRunState] = useState("");

    const style = { margin: -3 };

    const checkEarnedRunState = (value) => {
        if (value === earnedRunState) {
            setEarnedRunState("");
            dispatch(set_失分_state(""))
            dispatch(set_自責分_state(""))
        } else {
            dispatch(set_checkEarnedRunStateDialogOpen_state(true))
            setEarnedRunState(value);
        };
    };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={earnedRunState} onClick={(event) => { checkEarnedRunState(event.target.value) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="自責分" control={<Radio size="small" color="primary" />} label="自責分" />
                <FormControlLabel style={style} value="非自責分" control={<Radio size="small" color="primary" />} label="非自責分" />
            </RadioGroup>
        </FormControl>
    );
}

export default EarnedRunState;
