import { useState } from "react";
import { useAppDispatch } from '../app/hooks';

import {
    set_gameDate_state, set_gameNumber_state, set_gameTime_state,
    set_場次_state, set_日期_state, set_比賽_state, set_主審_state, set_場地_state, set_referees_state
} from "../slices/baseballApp";
import Airtable from 'airtable';

export default function useSetGameInfo() {

    interface GameInfo {
        賽事日期: string
        賽事時間: string
        賽事地點: string
        賽事天氣: string
        賽事名稱: string
        賽事等級: string
        賽事場次: string
        客隊名稱: string
        主隊名稱: string
        主審姓名: string
        一壘審姓名: string
        二壘審姓名: string
        三壘審姓名: string
        左線審姓名: string
        右線審姓名: string
    }

    const dispatch = useAppDispatch();

    const [allGameInfo, setAllGameInfo] = useState<GameInfo[]>([]);
    const [gameInfoLoadingState, setGameInfoLoadingState] = useState(false);

    function handleSetGameInfo(recordApiKey: string) {

        setAllGameInfo([]);
        const recordBase = new Airtable({ apiKey: 'keya2Fq5zTPhOH0Ld' }).base(recordApiKey);

        recordBase('raw_game_info')
            .select({ view: 'Grid view' })
            .eachPage((records: any, fetchNextPage) => {
                dispatch(set_gameDate_state(records[0].fields.賽事日期))
                dispatch(set_gameTime_state(records[0].fields.賽事時間))
                dispatch(set_gameNumber_state(records[0].fields.賽事場次))

                dispatch(set_日期_state(records[0].fields.賽事日期))
                dispatch(set_比賽_state(records[0].fields.賽事名稱))
                dispatch(set_場次_state(records[0].fields.賽事場次))
                dispatch(set_主審_state(records[0].fields.主審姓名))
                dispatch(set_場地_state(records[0].fields.賽事地點))

                dispatch(set_referees_state({
                    主審姓名: records[0].fields.主審姓名,
                    一壘審姓名: records[0].fields.一壘審姓名,
                    二壘審姓名: records[0].fields.二壘審姓名,
                    三壘審姓名: records[0].fields.三壘審姓名,
                    左線審姓名: records[0].fields.左線審姓名,
                    右線審姓名: records[0].fields.右線審姓名,
                }))

                setAllGameInfo([{
                    賽事日期: records[0].fields.賽事日期,
                    賽事時間: records[0].fields.賽事時間,
                    賽事地點: records[0].fields.賽事地點,
                    賽事天氣: records[0].fields.賽事天氣,
                    賽事名稱: records[0].fields.賽事名稱,
                    賽事等級: records[0].fields.賽事等級,
                    賽事場次: records[0].fields.賽事場次,
                    客隊名稱: records[0].fields.客隊名稱,
                    主隊名稱: records[0].fields.主隊名稱,
                    主審姓名: records[0].fields.主審姓名,
                    一壘審姓名: records[0].fields.一壘審姓名,
                    二壘審姓名: records[0].fields.二壘審姓名,
                    三壘審姓名: records[0].fields.三壘審姓名,
                    左線審姓名: records[0].fields.左線審姓名,
                    右線審姓名: records[0].fields.右線審姓名,
                }])
                setGameInfoLoadingState(false) // 釋放讀取狀態
            });
    }

    return {
        allGameInfo, gameInfoLoadingState, setAllGameInfo, setGameInfoLoadingState, handleSetGameInfo
    };

}