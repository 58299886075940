import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_球種_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const BallType = () => {

    let records = useAppSelector(state => state.baseballAppReducer.records);
    let ballTypeValue = records.球種;

    let dispatch = useAppDispatch();
    let style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={ballTypeValue} onClick={(e) => { dispatch(set_球種_state(e.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="速球" control={<Radio size="small" color="primary" />} label="速球" />
                <FormControlLabel style={style} value="二縫線" control={<Radio size="small" color="primary" />} label="二縫線" />
                <FormControlLabel style={style} value="滑球" control={<Radio size="small" color="primary" />} label="滑球" />
                <FormControlLabel style={style} value="曲球" control={<Radio size="small" color="primary" />} label="曲球" />
                <FormControlLabel style={style} value="變速" control={<Radio size="small" color="primary" />} label="變速球" />
                <FormControlLabel style={style} value="指叉球" control={<Radio size="small" color="primary" />} label="指叉球" />
                <FormControlLabel style={style} value="卡特" control={<Radio size="small" color="primary" />} label="卡特球" />
                <FormControlLabel style={style} value="無法判定" control={<Radio size="small" color="primary" />} label="無法判定" />
            </RadioGroup>
        </FormControl>
    );
}

export default BallType;
