import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import { set_事件_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

const PlayPass = () => {

    const dispatch = useAppDispatch()
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const playPass = records.事件;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <RadioGroup value={playPass} onClick={(event) => { dispatch(set_事件_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="6-3" control={<Radio size="small" color="primary" />} label="6-3" />
                <FormControlLabel style={style} value="5-3" control={<Radio size="small" color="primary" />} label="5-3" />
                <FormControlLabel style={style} value="3-u" control={<Radio size="small" color="primary" />} label="3U" />
                <FormControlLabel style={style} value="1-3" control={<Radio size="small" color="primary" />} label="1-3" />
                <FormControlLabel style={style} value="4-3" control={<Radio size="small" color="primary" />} label="4-3" />
                <FormControlLabel style={style} value="3-1" control={<Radio size="small" color="primary" />} label="3-1" />
            </RadioGroup>
        </FormControl>
    );
}

export default PlayPass;
