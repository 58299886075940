import React from 'react';
import { useAppSelector, useAppDispatch } from '../app/hooks';

import { set_打點_state } from '../slices/baseballApp';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';

const RbiState = () => {

    const dispatch = useAppDispatch();
    const records = useAppSelector(state => state.baseballAppReducer.records);

    const rbiState = records.打點;
    const style = { margin: -3 };

    return (
        <FormControl component="fieldset">
            <FormLabel>打點</FormLabel>
            <RadioGroup value={rbiState} onClick={(event) => { dispatch(set_打點_state(event.target.value)) }} row aria-label="radios" name="radios">
                <FormControlLabel style={style} value="1" control={<Radio size="small" color="primary" />} label="1" />
                <FormControlLabel style={style} value="2" control={<Radio size="small" color="primary" />} label="2" />
                <FormControlLabel style={style} value="3" control={<Radio size="small" color="primary" />} label="3" />
                <FormControlLabel style={style} value="4" control={<Radio size="small" color="primary" />} label="4" />
            </RadioGroup>
        </FormControl>
    );
}

export default RbiState;
