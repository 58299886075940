import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'

import baseballAppReducer from '../slices/baseballApp'

import { loggerMiddlware } from '../middlewares/loggerMiddlware'
// import { todoApiService } from '../services/todoApi'

const reducers = combineReducers({
    baseballAppReducer,
    // [todoApiService.reducerPath]: todoApiService.reducer
})

const store = configureStore({
    reducer: reducers,
    middleware: (getCurrentMiddlewares) => {
        return getCurrentMiddlewares()
            .concat(loggerMiddlware)
        // .concat(todoApiService.middleware)
    }
})

export type RootState = ReturnType<typeof reducers>
export type AppDispatch = typeof store.dispatch

export default store